import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import initProgressBar from '../assets/images/progressBar/init.svg'
import progressBar1 from '../assets/images/progressBar/progres1.svg'
import progressBar2 from '../assets/images/progressBar/progres2.svg'
import progressBar3 from '../assets/images/progressBar/progres3.svg'
import './progressBarStyles.css'
export const CustomeProgressBar = ({ data }) => {
    // console.log('dattata showww?????????', data)
    const percent = 40
    return (
        <>
            {data?.rejected_by !== null ?

                <>
                    {data?.hod_approved_amount != null ?
                        <ProgressBar
                            percent={data?.hod_approved == "no" || data?.mgmnt_approved == "no" ? 100 : 70}
                            filledBackground="linear-gradient(to right, #FFC332, #FF9234, #E74A2C, #D92027)"
                        >
                            {/* positionItem */}
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className="d-flex column alignItem-center mt-40px" style={{ marginTop: "58px" }}>
                                        <img
                                            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                            width="30"
                                            src={initProgressBar}
                                        />
                                        <div className="m-10px commonGraycolor light0_875Rem">Submitted</div>
                                        <span className="commonGraycolor light0_875Rem"> Amount : Rs {data?.total_amount}</span>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (

                                    <div className="d-flex column alignItem-center mt-40px">
                                        <img
                                            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, transform: "translateY(9px)" }}
                                            width="30"
                                            src={progressBar1}
                                        />
                                        <div className="m-10px commonGraycolor light0_875Rem">Approved by HOD</div>
                                        <span className="commonGraycolor light0_875Rem">Amount : Rs {data?.hod_approved_amount === null || data?.hod_approved_amount === '' ? "" : (data?.hod_approved_amount)}</span>
                                    </div>

                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className="d-flex column alignItem-center mt-40px">
                                        {
                                            data?.hod_approved == "no" || data?.mgmnt_approved == "no" ?
                                                <>
                                                    <svg width="45" height="45" viewBox="0 0 56 56" fill="red" style={{ transform: "translateY(13px)" }} xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_636_10947)">
                                                            <circle cx="28" cy="24" r="16" fill="red" />
                                                        </g>
                                                        <path d="M20.8516 16L36.0002 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                                        <path d="M35.1484 16L19.9998 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                                        <defs>
                                                            <filter id="filter0_d_636_10947" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="6" />
                                                                <feComposite in2="hardAlpha" operator="out" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0.301961 0 0 0 0 0.32549 0 0 0 0 0.878431 0 0 0 0.18 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_636_10947" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_636_10947" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </> :
                                                <img
                                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                    width="30"
                                                    src={progressBar1}
                                                />

                                        }

                                        <div className="m-10px commonGraycolor bold0_875Rem redColor">{data?.status}</div>
                                        <span className="commonGraycolor light0_875Rem ">{data?.status +`  by ${data?.rejected_by}`}</span>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar> :
                        <ProgressBar
                            percent={data?.hod_approved == "no" || data?.mgmnt_approved == "no" ? 100 : 70}
                            filledBackground="linear-gradient(to right, #FFC332, #FF9234, #E74A2C, #D92027)"
                        >
                            {/* positionItem */}
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className="d-flex column alignItem-center mt-40px" style={{ marginTop: "58px" }}>
                                        <img
                                            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                            width="30"
                                            src={initProgressBar}
                                        />
                                        <div className="m-10px commonGraycolor light0_875Rem">Submitted</div>
                                        <span className="commonGraycolor light0_875Rem"> Amount : Rs {data?.total_amount}</span>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className="d-flex column alignItem-center mt-40px">
                                        {
                                            data?.hod_approved == "no" || data?.mgmnt_approved == "no" ?
                                                <>
                                                    <svg width="45" height="45" viewBox="0 0 56 56" fill="red" style={{ transform: "translateY(13px)" }} xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_636_10947)">
                                                            <circle cx="28" cy="24" r="16" fill="red" />
                                                        </g>
                                                        <path d="M20.8516 16L36.0002 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                                        <path d="M35.1484 16L19.9998 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                                        <defs>
                                                            <filter id="filter0_d_636_10947" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="6" />
                                                                <feComposite in2="hardAlpha" operator="out" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0.301961 0 0 0 0 0.32549 0 0 0 0 0.878431 0 0 0 0.18 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_636_10947" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_636_10947" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </> :
                                                <img
                                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                    width="30"
                                                    src={progressBar1}
                                                />

                                        }

                                        <div className="m-10px commonGraycolor bold0_875Rem redColor">{data?.status}</div>
                                        <span className="commonGraycolor light0_875Rem ">{data?.rejected_by}</span>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>}


                
                
                </>
                :
                <>
                    {
                        data?.show_to_mgmnt === "no" ?
                            <ProgressBar
                                percent={data?.hr_approved === "yes" ? 100 : data?.hod_approved === "yes" ? 50 : 0}
                                // percent={45}
                                filledBackground="linear-gradient(to right, #FFC332, #FF9234, #E74A2C, #D92027)"
                            >
                                {/* positionItem */}
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px">
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={initProgressBar}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Submitted</div>
                                            <span className="commonGraycolor light0_875Rem"> Amount : {data?.total_amount}</span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px">
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={progressBar1}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Approved by HOD</div>
                                            <span className="commonGraycolor light0_875Rem">{data?.hod_approved_amount === null || data?.hod_approved_amount === '' ? "" : (data?.hod_approved_amount)}</span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px " style={{ width: '10vw' }}>
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={progressBar3}
                                            />

                                            <div className="m-10px commonGraycolor light0_875Rem">Approved by HR</div>
                                            <span className="commonGraycolor light0_875Rem">{data?.approved_amount === null || data?.approved_amount === '' ? "" : (data?.approved_amount)}</span>
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>
                            :
                            <ProgressBar
                                percent={data?.hr_approved === "yes" ? 100 : data?.mgmnt_approved === "yes" ? 66.6 : data?.hod_approved === "yes" ? 33 : 0}
                                filledBackground="linear-gradient(to right, #FFC332, #FF9234, #E74A2C, #D92027)"
                            >
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px">
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={initProgressBar}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Submitted</div>
                                            <span className="commonGraycolor light0_875Rem">Amount : {data?.total_amount}</span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px">
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={progressBar1}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Approved by HOD</div>
                                            <span className="commonGraycolor light0_875Rem">{data?.hod_approved_amount === null || data?.hod_approved_amount === '' ? "" : (data?.hod_approved_amount)}</span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px">
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={progressBar2}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Approved by Management</div>
                                            <span className="commonGraycolor light0_875Rem">{data?.mgmnt_approved_amount === null || data?.mgmnt_approved_amount === '' ? "" : (data?.mgmnt_approved_amount)}</span>

                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <div className="d-flex column alignItem-center mt-40px " style={{ width: '10vw' }}>
                                            <img
                                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                width="30"
                                                src={progressBar3}
                                            />
                                            <div className="m-10px commonGraycolor light0_875Rem">Approved by HR</div>
                                            <span className="commonGraycolor light0_875Rem">{data?.approved_amount === null || data?.approved_amount === '' ? "" : `Approved Amount : ${data?.approved_amount}`}</span>
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>

                    }
                </>
            }


        </>


    )
}