import React from 'react';

const ProgressBar = ({ progress }) => {
    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: '20px 0'
    };

    const fillerStyles = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: progress < 100 ? '#00695c' : '#43a047',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 0.5s ease-in-out'
    };

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${progress.toFixed(0)}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
