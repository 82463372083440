import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import description from '../../assets/images/description.svg'
import '../expenseDetail/expenseDetailHome.css'
import i from '../../assets/images/i.svg'
import parking from '../../assets/images/travel/parking.svg'
import toll from '../../assets/images/travel/toll.svg';
import distance from '../../assets/images/travel/distance.svg'
import amount from '../../assets/images/travel/amount.svg'
import city from '../../assets/images/city.svg'
import { BlueCommonButton, RedCommonButton, WhiteCommonButton, CancelCommonButton } from '../../components/button.tsx'
import { BrowserRouter as Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { formatCurrency } from "../../service/Logics.js";
import Moment from "react-moment";
import closeArrow from '../../assets/images/closeArrow.svg'
import openArrow from '../../assets/images/openArrow.svg'
import cross from '../assets/images/cross.svg'
import axios from "axios";
import { expenseUrl, initUrl } from "../../service/url.js";
import { Tooltip } from "@mui/material";
import { ViewInvoices } from "../../components/viewInvoices.tsx";
import LoadingSpinner from "../../components/loader.tsx";

export const Bulkapprovedpriview = ({ setpreviewPopupShow, violationMetaData, setViolation }) => {

    const expIds = violationMetaData?.expense?.map((item) => { return item?.exp_id })?.join(",")?.replace(/['"]+/g, '')
    // console.log(violationMetaData,expIds, "<<ExpenseData")
    const [editable, setEditable] = useState(false)
    const [activeCard, setActiveCard] = useState('')
    const navigate = useNavigate();
    const { state } = useLocation();
    const [expenseDetailsData, setexpenseDetailsData] = useState([])

    const handleTab = (tabName: string) => {
        setActiveCard(tabName === activeCard ? "" : tabName)
    }
    const renderTeamMembers = (members) => {
        const maxDisplayMembers = 1;
        if (members.length <= maxDisplayMembers) {
            return members.join(', ');
        } else {
            const displayedMembers = [members.slice(0, maxDisplayMembers)];
            const moreMembersCount = members.length - maxDisplayMembers;
            return (
                <>
                    {displayedMembers.join(', ')}
                    <div>+{moreMembersCount} more</div>
                </>
            );
        }
    };
    const [invoiceView, setinvoiceView] = useState(false)
    const [invoiceList, setinvoiceList] = useState([])
    const [isloading, setloading] = useState(false)
    const handleImageDownload = (base64, fileName) => {
        // console.log(base64, "<<<base64", fileName);
        setinvoiceView(true)
        setinvoiceList(base64?.split(","))
    };
    const filterKeys = (obj, keysToExclude) =>
        Object.fromEntries(
            Object.entries(obj).filter(([key]) => !keysToExclude.includes(key))
        );
    const excludedKeys = ['id', 'expense_id', 'updated_at', 'created_at'];
    const excludedTravelKeys = ['id', 'expense_id', 'updated_at', 'created_at', "invoices", "amount_remark"];

    const fetchExpense = async () => {
        setloading(true)
        await axios.get(`${initUrl}get-expenses-grouped-details?expense_ids=${expIds}`).then((res) => {
            setloading(false)
            if (res?.data?.responseCode == 200) {
                setexpenseDetailsData(res?.data?.result)
                // console.log(res?.data?.result, "<<<result<<<<<<<SDf");
            }
        }).catch((err) => {
            setloading(false)
            // console.log(err, "<<<<<asd");
        })
    }

    useEffect(() => { fetchExpense() }, [])

    return (
        <div className="popup-container">
            <div className='mt-20px container-main'>
                <LoadingSpinner loading={isloading} />
                <div >
                    <div className=' m-10px textAlign-Start row '>
                        <span className="bold1Rem commonBlackcolor">
                            Bulk Approve Expense Details preview
                        </span>
                        <span>
                            {/* <img  */}
                        </span>
                    </div>
                </div>
                <div style={{ padding: "10px" }}>
                    {
                        expenseDetailsData?.map((expenseItem, index) => {
                            const allexpenseData = expenseItem?.expense_details
                            // console.log(expenseItem?.expense_details , "<<<<<<<<,expenseItem");
                            return (
                                <div key={index} style={{ border: "1px solid #ccc", padding: "5px" }}>
                                    <div className="d-flex bulkpreviewhander">
                                        <p> {index + 1}. User ID : {allexpenseData?.user_id}</p>
                                        <p>Receipt Number : {allexpenseData?.receipt_no}</p>
                                        <p> Submited total amount : {formatCurrency(Number(allexpenseData?.total_amount),)}</p>

                                    </div>
                                    {allexpenseData?.meals.length == null || allexpenseData?.meals.length == 0  //meal card
                                        ? null :
                                        <div className="moduleBorderWithoutPadding ">
                                            <div onClick={() => handleTab("meal")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                                <span className={activeCard == "meal" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Meal Expense</span>
                                                <img src={activeCard == "meal" ? openArrow : closeArrow} />
                                            </div>
                                            <hr />
                                            <div>
                                                {
                                                    activeCard == "meal" &&
                                                    <>
                                                        {allexpenseData?.meals?.map((entry, index) => {
                                                            const filteredEntry = filterKeys(entry, excludedKeys);
                                                            console.log(filteredEntry, "<<<filteredEntry",);

                                                            return (
                                                                <div key={index} className="ml-10px">
                                                                    <div className="d-flex row aa flex-wrap">
                                                                        {Object?.entries(filteredEntry)?.map(([key, value]) => (
                                                                            <div key={key}>
                                                                                {value !== "" && value !== null
                                                                                    && key != "undefined"
                                                                                    && key.toLowerCase() != "uid"
                                                                                    && (!key.includes("_Isinvoice"))
                                                                                    && key !== "invoice_file"
                                                                                    //  && !key.includes("_invoice_file")
                                                                                    ? (
                                                                                        <div className="bb">
                                                                                            <div className='m-10px'>
                                                                                                {
                                                                                                    !(key == 'lunch_invoice' || key == 'breakfast_invoice' || key == 'dinner_invoice' || key == 'lunch_invoice_file' || key == 'breakfast_invoice_file' || key == 'dinner_invoice_file') &&
                                                                                                    <TextField
                                                                                                        id="input-with-icon-textfield"
                                                                                                        label={key === "ex_date" ? "Date" : key.charAt(0).toUpperCase() + key.slice(1)?.replace(/_/g, " ")}
                                                                                                        type={key === "ex_date" ? 'date' : "string"}
                                                                                                        defaultValue={key.includes("_tag") ? value.split(',')[0] : value}
                                                                                                        disabled={editable ? false : true}
                                                                                                        className='datepicker'
                                                                                                        InputProps={{
                                                                                                            startAdornment: (
                                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                                </InputAdornment>
                                                                                                            ),
                                                                                                        }}
                                                                                                        variant="standard"
                                                                                                    />
                                                                                                }
                                                                                                {
                                                                                                    typeof (value) != "Boolean" && key.includes("_invoice") ?
                                                                                                        (
                                                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                                                <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(value, 'file')}>
                                                                                                                    View {value ? key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1) : null}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                {key.includes("_tag") && value !== "" ? (
                                                                                    <div className='m-5px light0_875Rem d-flex'>
                                                                                        <Tooltip
                                                                                            title={`Tagged members: ${value}`} arrow>
                                                                                            <div className="d-flex">
                                                                                                <span className='m-5px light0_875Rem '>
                                                                                                    Tag: {renderTeamMembers(value?.split(',')?.map(member => member.trim()))}
                                                                                                </span>
                                                                                                <img src={i} className='iImg m-5px' />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                ) : null}

                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {allexpenseData?.travels.length == null || allexpenseData?.travels.length == 0  //travel card 
                                        ? null :
                                        <div className="moduleBorderWithoutPadding ">
                                            <div onClick={() => handleTab("travel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                                <span className={activeCard == "travel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Travel Expense</span>
                                                <img src={activeCard == "travel" ? openArrow : closeArrow} />
                                            </div>
                                            <hr />
                                            <div className='d-flex column pl-20px pr-20px' >
                                                {
                                                    activeCard == "travel" &&
                                                    <>
                                                        {
                                                            allexpenseData?.travels?.map((entry, index) => {
                                                                const filteredEntry = filterKeys(entry, excludedTravelKeys);
                                                                console.log(entry, "<<<<entry");
                                                                console.log(entry.invoices, "<<<<entry_invoice");
                                                                console.log(entry.invoices.amount, "<<<<entry_amount");
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="d-flex row aa flex-wrap">
                                                                            <div className='d-flex row flex-wrap'>
                                                                                {
                                                                                    entry.vehicle != "" &&
                                                                                    <div className='m-10px new-text-class'>
                                                                                        <TextField
                                                                                            id={`input-actualDistance-textfield`}
                                                                                            label={"Vehicle Name"}
                                                                                            type={"text"}
                                                                                            disabled={true}
                                                                                            placeholder={`Enter vehicle`}
                                                                                            InputProps={{
                                                                                                startAdornment: (
                                                                                                    <div>
                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                            <img src={distance} />
                                                                                                        </InputAdornment>
                                                                                                    </div>
                                                                                                ),
                                                                                            }}
                                                                                            variant="standard"
                                                                                            value={entry.vehicle}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                <div className='m-10px'>
                                                                                    <TextField
                                                                                        id="input-with-icon-textfield"
                                                                                        label="Date"
                                                                                        placeholder='Enter Date here'
                                                                                        type='date'
                                                                                        disabled={true}
                                                                                        className='datepicker'
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <div>
                                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                                        {/* {field.title === 'amount' ? <img src={bill} /> : field.title === "Calculated distance" ? <img src={distance} /> : field.title === "date" ? "" : field.title === "amount" ? <img src={bill} /> : <img src={map} />} */}
                                                                                                    </InputAdornment>
                                                                                                </div>
                                                                                            ),
                                                                                        }}

                                                                                        variant="standard"
                                                                                        value={ entry.travel_date || entry.ex_date}
                                                                                    // onChange={(e) => updateallexpenseData?.travels("ex_date", e.target.value, dataValue.uid)}
                                                                                    />
                                                                                </div>
                                                                                <div className='m-10px d-flex'>
                                                                                    <TextField
                                                                                        id={`input-startLoaction-textfield`}
                                                                                        label={"Start Location"}
                                                                                        type={"string"}
                                                                                        disabled={true}
                                                                                        multiline
                                                                                        maxRows={4}
                                                                                        placeholder={`Choose start location`}
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <div
                                                                                                // onClick={() => openMapFunction('start_location', dataValue.uid, item?.policies[0])}
                                                                                                >
                                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                                        <img src={distance} />
                                                                                                    </InputAdornment>
                                                                                                </div>
                                                                                            ),
                                                                                        }}
                                                                                        variant="standard"
                                                                                        value={entry?.start_location}
                                                                                    />
                                                                                    {entry?.start_location !== "" &&
                                                                                        <Tooltip
                                                                                            key={entry.start_location}
                                                                                            title={entry.start_location} arrow >
                                                                                            <div className="d-flex">
                                                                                                <span className='m-5px light0_875Rem '>
                                                                                                    {/* {'Full address'} */}
                                                                                                </span>
                                                                                                <img src={i} className='iImg m-5px' />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </div>
                                                                                <div className='m-10px d-flex'>
                                                                                    <TextField
                                                                                        id={`input-endLoaction-textfield`}
                                                                                        label={"End Location"}
                                                                                        type={"string"}
                                                                                        disabled={true}
                                                                                        multiline
                                                                                        maxRows={4}
                                                                                        placeholder={`Choose end location`}
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <div
                                                                                                // onClick={() => openMapFunction('end_location', entry.uid, item?.policies[0])}
                                                                                                >
                                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                                        <img src={distance} />
                                                                                                    </InputAdornment>
                                                                                                </div>
                                                                                            ),
                                                                                        }}
                                                                                        variant="standard"
                                                                                        value={entry.end_location}
                                                                                    />

                                                                                    {entry?.end_location !== "" &&
                                                                                        <Tooltip
                                                                                            title={entry.end_location} arrow >
                                                                                            <div className="d-flex">
                                                                                                <span className='m-5px light0_875Rem '>
                                                                                                    {/* {'Full address'} */}
                                                                                                </span>
                                                                                                <img src={i} className='iImg m-5px' />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    entry.actual_distance &&
                                                                                    <div className='m-10px new-text-class'>
                                                                                        <TextField
                                                                                            id={`input-actualDistance-textfield`}
                                                                                            label={"Actual Distance"}
                                                                                            type={"number"}
                                                                                            disabled={true}
                                                                                            placeholder={`Enter actual distance`}
                                                                                            InputProps={{
                                                                                                startAdornment: (
                                                                                                    <div>
                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                            <img src={distance} />
                                                                                                        </InputAdornment>
                                                                                                    </div>
                                                                                                ),
                                                                                            }}
                                                                                            variant="standard"
                                                                                            value={entry?.actual_distance || entry?.calculated_distance}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                {entry?.invoices?.map((invoice, idx) => {
                                                                                if (invoice.amount > 0) {  // Check if the amount is greater than 0
                                                                                    switch(invoice.ei_type) {
                                                                                    case 'amount':
                                                                                        return (
                                                                                            <>
                                                                                                <div key={idx} className='m-10px new-text-class'>
                                                                                                    <TextField
                                                                                                    id={`input-amount-textfield-${idx}`}
                                                                                                    label="Amount"
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (
                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                            <img src={amount} />
                                                                                                        </InputAdornment>
                                                                                                        ),
                                                                                                    }}
                                                                                                    variant="standard"
                                                                                                    value={formatCurrency(Number(invoice.amount), "₹")}
                                                                                                    />
                                                                                                </div>
                                                                                                {
                                                                                                    invoice.has_base_location &&
                                                                                                    <div className='m-10px new-text-class'>
                                                                                                        <TextField
                                                                                                            id={`input-actualDistance-textfield`}
                                                                                                            label={"Has base location"}
                                                                                                            type={"text"}
                                                                                                            disabled={true}
                                                                                                            placeholder={``}
                                                                                                            InputProps={{
                                                                                                                startAdornment: (
                                                                                                                    <div>
                                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                                            <img src={distance} />
                                                                                                                        </InputAdornment>
                                                                                                                    </div>
                                                                                                                ),
                                                                                                            }}
                                                                                                            variant="standard"
                                                                                                            value={invoice.has_base_location}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    invoice?.is_selfBook &&
                                                                                                    <div className='m-10px'>
                                                                                                        <TextField
                                                                                                            id="input-with-icon-textfield"
                                                                                                            label="Self Booked"
                                                                                                            type='text'
                                                                                                            disabled={true}
                                                                                                            InputProps={{
                                                                                                                startAdornment: (
                                                                                                                    <div>
                                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                                            {/* {field.title === 'amount' ? <img src={bill} /> : field.title === "Calculated distance" ? <img src={distance} /> : field.title === "date" ? "" : field.title === "amount" ? <img src={bill} /> : <img src={map} />} */}
                                                                                                                        </InputAdornment>
                                                                                                                    </div>
                                                                                                                ),
                                                                                                            }}
                                                                                                            variant="standard"
                                                                                                            value={invoice?.is_selfBook}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    invoice.invoice_file &&
                                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                                        <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(invoice.invoice_file, 'file')}>
                                                                                                            View Invoice
                                                                                                        </span>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    invoice.bording_pass &&
                                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                                        <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(invoice.bording_pass, 'file')}>
                                                                                                            View Boarding Pass
                                                                                                        </span>
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    case 'parking':
                                                                                        return (
                                                                                            <>
                                                                                                <div key={idx} className='m-10px new-text-class'>
                                                                                                    <TextField
                                                                                                    id={`input-parking-textfield-${idx}`}
                                                                                                    label="Parking Amount"
                                                                                                    type="number"
                                                                                                    disabled={true}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (
                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                            <img src={parking} />
                                                                                                        </InputAdornment>
                                                                                                        ),
                                                                                                    }}
                                                                                                    variant="standard"
                                                                                                    value={invoice.amount}
                                                                                                    />
                                                                                                </div>
                                                                                                {
                                                                                                    invoice.invoice_file &&
                                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                                        <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(invoice.invoice_file, 'file')}>
                                                                                                            View Invoice
                                                                                                        </span>
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    
                                                                                    default:
                                                                                        return null;
                                                                                    }
                                                                                }
                                                                                return null;
                                                                                })}
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {allexpenseData?.hotels.length === null || allexpenseData?.hotels.length === 0 ? null :    //hotel card 
                                        <div className="moduleBorderWithoutPadding">
                                            <div onClick={() => handleTab("hotel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                                <span className={activeCard == "hotel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Hotel Expense</span>
                                                <img src={activeCard == "hotel" ? openArrow : closeArrow} />
                                            </div>
                                            <hr />
                                            <div className='d-flex column pl-20px pr-20px' >
                                                {
                                                    activeCard == "hotel" &&
                                                    <>
                                                        {
                                                            allexpenseData?.hotels?.map((item, id) => {
                                                                const invoiceFile = item.invoices?.find((item) => item.ei_type == "amount")
                                                                console.log(item, "<<<<<'amount'");

                                                                return (
                                                                    <div className='d-flex row alignItem-center flex-wrap'>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="City"
                                                                                type='string'
                                                                                disabled={editable ? false : true}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            <img src={city} />
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                value={item?.city}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Check In"
                                                                                type='date'
                                                                                value={item?.check_in_date}
                                                                                disabled={editable ? false : true}
                                                                                className='datepicker'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Check Out"
                                                                                type='date'
                                                                                disabled={editable ? false : true}
                                                                                className='datepicker'
                                                                                value={item?.check_out_date}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Number of days"
                                                                                value={item?.days_count}
                                                                                disabled={editable ? false : true}
                                                                                className='datepicker'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="GST %"
                                                                                value={invoiceFile?.gst_percentage + "%"}
                                                                                disabled={editable ? false : true}
                                                                                className='datepicker'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Amount (inclusive GST)"
                                                                                value={formatCurrency(Number(invoiceFile?.amount_withGst), '₹')}
                                                                                disabled={editable ? false : true}
                                                                                className='datepicker'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                            />
                                                                        </div>
                                                                        {
                                                                            item?.invoices ? (
                                                                                item.invoices?.map((invoice) => (
                                                                                    <>
                                                                                        <div className='m-10px' key={invoice.id}>
                                                                                            <TextField
                                                                                                id="input-with-icon-textfield"
                                                                                                label="Amount Amount (Excluding GST)"
                                                                                                type='number'
                                                                                                defaultValue={invoice?.amount ? invoice?.amount : ""}
                                                                                                disabled={editable ? false : true}
                                                                                                placeholder='Select location'
                                                                                                InputProps={{
                                                                                                    startAdornment: (
                                                                                                        <InputAdornment position="start">
                                                                                                            <img src={amount} />
                                                                                                        </InputAdornment>
                                                                                                    ),
                                                                                                }}
                                                                                                onChange={(e) => {
                                                                                                    console.log("<<<allexpenseData?.hotels", item);
                                                                                                    updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice.id)

                                                                                                }}
                                                                                                // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                                variant="standard"
                                                                                            />
                                                                                        </div>
                                                                                        {
                                                                                            invoice?.invoice_file !== null ?
                                                                                                <div className='m-10px d-flex alignItem-center'>
                                                                                                    <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                                        View Invoice File
                                                                                                    </span>
                                                                                                </div>
                                                                                                : ""
                                                                                        }
                                                                                    </>
                                                                                ))
                                                                            ) : (

                                                                                <>         <div className='m-10px'>
                                                                                    <TextField
                                                                                        id="input-with-icon-textfield"
                                                                                        label="Amount"
                                                                                        type='number'
                                                                                        defaultValue={item?.amount}
                                                                                        disabled={editable ? false : true}
                                                                                        placeholder='Select location'
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <img src={amount} />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice.id)
                                                                                        }}
                                                                                        // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>


                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                        <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                            View Invoice File
                                                                                        </span>
                                                                                    </div>

                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            item?.hotel_tag !== "" ?
                                                                                <>
                                                                                    <div className='m-5px light0_875Rem d-flex'>
                                                                                        <Tooltip
                                                                                            title={`Tagged members: ${item?.hotel_tag}`} arrow>
                                                                                            <div className="d-flex">
                                                                                                <span className='m-5px light0_875Rem '>
                                                                                                    Tag: {renderTeamMembers(item?.hotel_tag?.split(',')?.map(member => member.trim()))}
                                                                                                </span>
                                                                                                <img src={i} className='iImg m-5px' />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    </div>

                                                                                </> : ""
                                                                        }


                                                                    </div>

                                                                )
                                                            }
                                                            )}

                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {allexpenseData?.das.length === null || allexpenseData?.das.length === 0 ? null :   // da card 
                                        <div className="moduleBorderWithoutPadding">
                                            <div onClick={() => handleTab("da")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                                <span className={activeCard == "da" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>DA Expense</span>
                                                <img src={activeCard == "da" ? openArrow : closeArrow} />
                                            </div>
                                            <hr />
                                            <div className='d-flex column pl-20px pr-20px' >
                                                {
                                                    activeCard == "da" &&
                                                    <>
                                                        {allexpenseData?.das?.map((item, id) =>
                                                            <div className='d-flex row alignItem-center flex-wrap' key={id}>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Date"
                                                                        type='date'
                                                                        disabled={editable ? false : true}
                                                                        value={item?.ex_date}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="City"
                                                                        disabled={editable ? false : true}
                                                                        value={item?.city}
                                                                        type='string'
                                                                        placeholder='Enter amount'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={city} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Amount"
                                                                        type='text'
                                                                        disabled={editable ? false : true}
                                                                        value={formatCurrency(Number(item?.amount || 0), "₹")}
                                                                        placeholder='Amount'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={amount} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className="m-10px">
                                                                    {
                                                                        item?.invoice_file ?
                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(item?.invoice_file, 'file')}>
                                                                                    View Invoice File
                                                                                </span>
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {allexpenseData?.others.length === null || allexpenseData?.others.length === 0 ? null :    //hotel card 
                                        <div className="moduleBorderWithoutPadding">
                                            <div onClick={() => handleTab("other")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                                <span className={activeCard == "other" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Other Expense</span>
                                                <img src={activeCard == "other" ? openArrow : closeArrow} />
                                            </div>
                                            <hr />
                                            <div className='d-flex column pl-20px pr-20px' >
                                                {
                                                    activeCard === "other" &&
                                                    <>
                                                        {
                                                            allexpenseData?.others?.map((item, id) =>
                                                                <div className='d-flex row alignItem-center flex-wrap'>
                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Date"
                                                                            type='date'
                                                                            disabled={editable ? false : true}
                                                                            value={item?.date}
                                                                            className='datepicker'
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            variant="standard"
                                                                        />
                                                                    </div>
                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Title"
                                                                            type='string'
                                                                            disabled={editable ? false : true}
                                                                            placeholder='Enter title'
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src={city} />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            value={item?.title}
                                                                            variant="standard"
                                                                        />
                                                                    </div>
                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Amount"
                                                                            value={formatCurrency(Number(item?.amount || 0), "₹")}
                                                                            disabled={editable ? false : true}
                                                                            className='datepicker'
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            variant="standard"
                                                                        />
                                                                    </div>

                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Remark"
                                                                            value={item?.remark}
                                                                            disabled={editable ? false : true}
                                                                            className='datepicker'
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            variant="standard"
                                                                        />
                                                                    </div>

                                                                    <div className="m-10px">
                                                                        {
                                                                            item?.invoice_file !== null ?
                                                                                <div className='m-10px d-flex alignItem-center'>
                                                                                    <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(item?.invoice_file, 'file')}>
                                                                                        View Invoice File
                                                                                    </span>
                                                                                </div>
                                                                                : ""
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

                </div>
                {/* footer */}
                {
                    <div className='d-flex row flex-wrap'>
                        <div className='m-10px'>
                            <CancelCommonButton
                                title={"Cancel"}
                                buttonClick={() => {
                                    setpreviewPopupShow(false)
                                }}
                            />
                        </div>
                        <div className='m-10px'>
                            <WhiteCommonButton
                                title={"Approve"}
                                subTitle={""}
                                buttonClick={() => {
                                    setpreviewPopupShow(false)
                                    setViolation(true)
                                }}
                            />
                        </div>

                    </div>
                }
                {
                    invoiceView &&
                    <ViewInvoices
                        close={() => setinvoiceView(false)}
                        data={invoiceList}
                    />
                }

            </div>
        </div>
    )
}
