import React, { useState } from 'react';

const PeginationComponent = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) { pageNumbers.push(i); }
    return (
        <nav>
            <ul className='pagination'>
                <li className='page-item'>
                    <a  className={`${1!= currentPage ? 'active' : ""}`}
                        onClick={() => {
                            console.log(pageNumbers?.length, ">>>", currentPage);
                            if (1!= currentPage) {
                                paginate(currentPage - 1)
                            }
                        }}
                    >
                        Previous
                    </a>
                </li>
                {pageNumbers.map(number => (
                    <li key={number} className='page-item'>
                        <a onClick={() => paginate(number)} href='#' className={`${number == currentPage ? "active" : ""}`}>
                            {number}
                        </a>
                    </li>
                ))}
                <li className='page-item'>
                    <a  className={`${pageNumbers?.length > currentPage ? 'active' : ""}`}
                        onClick={() => {
                            // console.log(pageNumbers?.length, ">>>", currentPage);
                            if (pageNumbers?.length > currentPage) {
                                paginate(currentPage + 1)
                            }
                        }}
                    >
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default PeginationComponent;