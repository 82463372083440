import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import search from '../assets/images/search.svg'
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { NewServiceCall } from '../service/config';
import { initUrl } from '../service/url';
import { useSelector } from 'react-redux';
import { selectData } from '../Redux/features/login/loginSlicer';
import { useNavigate } from 'react-router-dom';
const ariaLabel = { 'aria-label': 'description' };
export default function CustomizedSearchInput() {
const navigate = useNavigate()
  const [inputValue, setInputValue] = React.useState("")
  return (
    <Box
      component=""
      sx={{
        '& > :not(style)': { m: 1 }, width: { sm: '30vw', xs: '45vw' },
      }}
      noValidate
      autoComplete="off"
    >
      <Paper
        component=""
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', backgroundColor: '#F4F4F4', boxShadow: 'none', borderRadius: '10px' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search "
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          onKeyPress={(e)=>{ 
            if(e.key == "Enter"){navigate(`/search/${inputValue}`)}            
          }}
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton 
        type="button"
        onClick={()=>{
          navigate(`/search/${inputValue}`)
        }}
         sx={{ p: '10px' }} aria-label="search">
          <img src={search} />
        </IconButton>
      </Paper>
    </Box>
  );
}