import React, { useState, useEffect } from 'react';
import loginPage from '../../assets/images/loginPage.svg'
import './loginStyles.css'
import Logo from '../../assets/images/Logo.svg'
import TextField from '@mui/material/TextField';
import userNameImg from '../../assets/images/userName.svg';
import passWord from '../../assets/images/passWord.svg'
import InputAdornment from '@mui/material/InputAdornment';
import topRightIcon from '../../assets/images/topRightIcon.svg'
import bottomRightIcon from '../../assets/images/bottomRightIcon.svg.svg'

import { configUrlencoded } from '../../utils/constants'
import { toastContainer, notifyWarning, notifySuccess, notifyError } from '../../components/toast';

import { useDispatch, useSelector } from 'react-redux';
import { ServiceCall, NewServiceCall } from '../../service/config';

import { setData, selectData, setLoginStatus, setRole, setLoading } from '../../Redux/features/login/loginSlicer'
import { main, loginUrl, initUrl } from '../../service/url';
import { useNavigate } from 'react-router-dom';

export const LoginScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginData = useSelector(selectData);
    const [userName, setUserName] = useState("")
    const [userPass, setUserPass] = useState("")
    const [showPassword, setShowPassword] = useState(true)
// state for checking if the button is clicked or not
    const [isClicked , setIsClicked] = useState(false);


    // console.log("loginData>>", loginData)
    useEffect(() => {
        if (loginData?.loading === false) {
            // navigate('/home');
            // employeeRole()
        }
    }, [loginData])
    const login = async () => {
        //const url = main + loginUrl.userLogin
        const url = initUrl + loginUrl.userLogin
        const formData = new URLSearchParams();
        formData.append('UserCode', userName);
        formData.append('Password', userPass);
        formData.append('CompanyCode', 'Nihon');
         await ServiceCall(url, formData, configUrlencoded).then((res)=>{
            console.log(res);
             if (res.status === "success") {
            notifySuccess(res.Message)
            dispatch(setData(res.Data))
            dispatch(setLoginStatus(true))
            navigate('/home');
            employeeRole()

        }
        else if (res.status === "Fail") {
            notifyError(res.Message)
            dispatch(setLoginStatus(false))
            dispatch(setData([]))
        }
        
    }).catch((err)=>{
             notifyError(err.Message)
            console.log(err , "<<<<res");
            
         })
        
    }
    const employeeRole = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + loginUrl.employeeRole,
            headers: {},
            params: { employee_id: userName }
        };
        dispatch(setLoading(true))
        await NewServiceCall(config)
            .then((res) => {
                // setLoading(false)
                if (res.status === 200) {
                    // console.log(res?.data?.result, "getrole>>>");
                    
                    dispatch(setRole(res?.data?.result))
                    dispatch(setLoading(false))
                    navigate('/home');
                }
                else {
                    dispatch(setRole(''))
                    dispatch(setLoading(false))
                }
            })
            .catch((err) => {
                console.log("employeeRole err>>>", err)
            })
    }

    const validation = () => {

        if (userName === "") {
            notifyWarning("Enter user Name")

        }
        else if (userPass === "") {
            notifyWarning("Enter user Password")
        }
        else {
            setIsClicked(true);
            login()
        }
    }
    return (
        <div className='login-screen '>
            {/* Left side */}
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={loginPage} alt="Overlay" className='img'
                />
                <div className='textOn-Img d-flex column justfyContent-center alignItem-start'>
                    <span className='regular1_26Rem white'>WELCOME TO</span>
                    <span className='bold1_5Rem white'>EXPENSE MANAGEMENT</span>
                    <span className='bold1Rem white'>Login to Access dashboard</span>
                </div>
            </div>

            {/* Right side */}
            <div className='d-flex column justfyContent-center flex1 alignItem-center rightside'>
                <div>
                    <img src={topRightIcon} className='topRight' />
                    <div className='d-flex column justfyContent-start textAlign-Start'>
                        <img src={Logo} alt="logo" className="logoLogin" />
                        <span className='m-5px light1_375Rem commonGraycolor'>Login to your account</span>
                        <span className='m-5px bold2_75Rem darkBlack'>Welcome Back!</span>
                    </div>
                    <div className='d-flex column m-15px'>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Email"
                            onChange={(e) => setUserName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={userNameImg} />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </div>
                    <div className='d-flex column m-15px pos-relative'>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Password"
                            type={showPassword ? 'password' : "text"}
                            onChange={(e) => setUserPass(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={passWord} />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            onKeyPress={(e) => {
                                console.log(e.key, "<<<<<");
                                if (e.key == "Enter") {
                                    validation()
                                }
                            }}
                        />
                        <span className='eye-icon' onClick={() => { setShowPassword(!showPassword) }}>
                            <svg viewBox="0 0 24 24" width="24" height="24" fill="#3498db"><path d="M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z"></path></svg>
                        </span>
                    </div>
                    <div className='m-15px'>
                        <button
                            className={`button bold1_26Rem  ${userName === "" || userPass === "" ? "BGDisableButtonColor darkBlack" : "BGDActiveButtonColor white"} ${isClicked?"buttonclicked":""}`}
                            // onClick={() => login()}
                            onClick={() => validation()}
                            
                        >
                           {isClicked?"Logging You in":"Login"}
                        </button>
                    </div>
                    {toastContainer()}
                    <div className='m-15px'>
                        <span className='light0_875Rem commonGraycolor'>Forgotten your login details? <span className='bold0_875Rem darkBlack'>Contact Admin team.</span></span>
                    </div>
                    <img src={bottomRightIcon} className='bottomRight' />
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
