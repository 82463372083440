import React, { useEffect, useState, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import pen from '../../assets/images/pen.svg'
import uploadImg from '../../assets/images/smallUpload.svg'
import '../draft/draftScreenStyle.css'

import PaginationItem from '@mui/material/PaginationItem';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import { expenseUrl } from "../../service/url.js";
import axios from "axios";
import LoadingSpinner from "../../components/loader.tsx";
import SearchFound from "../../components/searchFound.tsx";

import { useDispatch, useSelector } from 'react-redux';
import { selectData } from '../../Redux/features/login/loginSlicer.js'

import { NewServiceCall } from '../../service/config.js';
import { admin, initUrl, expenseRequest } from '../../service/url.js'
import { toastContainer, notifySuccess, notifyWarning, notifyError } from '../../components/toast.js';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { WhiteCommonButton, BlueCommonButton } from "../../components/button.tsx";

import { ExportExcel } from "../../components/exportToExcel.js";
import Moment from 'react-moment';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ViolationPopup } from "./voilation.tsx";
import ProgressBar from '../../components/progressBar.tsx';
import PeginationComponent from "../../components/PeginationComponent.js";


const ExpenseDetails = `Here are the requested expense details of team members. You can check the request by clicking on the "View Details" button.`

export const ApprvoedAllRequestListScreen = () => {
    const [teamRequestData, settTamRequestData] = useState<any>([])
    const [teamRequestDataCopy, settTamRequestDataCopy] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const loginStatus = useSelector(selectData);
    const [finalAmount, setFinalAmount] = useState('')
    const locationData = window.location
    const user_id = loginStatus.items[0].empcode

    // console.log(user_id , "<<<<user_id" ,loginStatus);

    const [progress, setProgress] = useState(0);

    const setProgressBar = (newProgress) => {
        setProgress(newProgress);
    };

    const targetRef = useRef(null);
    useEffect(() => {
        getTeamRequestScreen()
    }, [])
    const roleName = loginStatus?.role?.role
    const getTeamRequestScreen = async () => {
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('role_id', '4');
        // formData.append('role_id', "4");
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + expenseRequest.getTeamRequestExpense,
            headers: {},
            data: formData
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {

                    const responseData = res?.data?.result || [];

                    const filteredData = responseData.filter(item => item.hr_approved === 'yes').filter(item => item.posted_to_nav == 0);

                    settTamRequestData(filteredData)
                    settTamRequestDataCopy(filteredData)




                }
                else {
                    settTamRequestData([])
                    settTamRequestDataCopy([])
                    notifyWarning("Something went wrong!!")
                }
                // console.log("getTeamRequestScreen res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                settTamRequestData([])
                settTamRequestDataCopy([])
                notifyWarning("Something went wrong!!")
                // console.log("getTeamRequestScreen reerrs>>>", err)
            })
    }
    const [showAll, setShowAll] = useState(true)
    const handleChange = (status: SelectChangeEvent) => {
        setShowAll(!showAll)
    };
    const [sortBy, setSortBy] = useState("")
    const handleChangeSortBy = (event: SelectChangeEvent) => {
        setSortBy(event.target.value)
    }

    const [filterType, setFilterType] = useState("")
    const [selectedUserId, setSelectedUserId] = useState("")
    const handleChangeType = (event: SelectChangeEvent) => {
        setFilterType(event.target.value)
    }
    const handleChangeUserId = (event: SelectChangeEvent) => {
        setSelectedUserId(event.target.value)
    }

    useEffect(() => {
        getMasterExpense()
    }, [])

    const getMasterExpense = async () => {
        setLoading(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.getExpenseMaster,
            headers: {},
        };
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setMasterExpenseType(res?.data?.result)
                }
                else {
                    setMasterExpenseType([])
                    notifyError("Something went wrong!!")
                }
                // console.log("getMasterExpense res>>>", res)
            })
            .catch((err) => {
                setMasterExpenseType([])
                setLoading(false)
                notifyError("Something went wrong!!")
                // console.log("getMasterExpense reerrs>>>", err)
            })
    }
    const [reportData, setReportData] = useState([]);

    const getReportData = async () => {
        try {
            setLoading(true);
            const uniqueUserIds = [...new Set(teamRequestData.map(el => el?.user_id))];
            const batchSize = 10; // Adjust batch size based on what your server can handle comfortably
            const userIdBatches = [];
    
            for (let i = 0; i < uniqueUserIds.length; i += batchSize) {
                userIdBatches.push(uniqueUserIds.slice(i, i + batchSize));
            }
    
            const totalBatches = userIdBatches.length;
            let completedBatches = 0;
            let allResults = [];
    
            for (const batch of userIdBatches) {
                const userIdParams = batch.join(',');
                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${expenseUrl.initialUrl}${expenseUrl.getReportData}?user_id=${userIdParams}&status=approved`,
                    headers: {}
                };
    
                const response = await NewServiceCall(config);
                if (response.status === 200) {
                    allResults = allResults.concat(response?.data?.result || []);
                } else {
                    console.error("Batch request failed with status:", response.status);
                    // Optionally, handle individual batch errors differently or retry logic
                }
    
                completedBatches++;
                const percentageComplete = (completedBatches / totalBatches) * 100;
                setProgressBar(percentageComplete); // Update your UI component that shows progress
            }
    
            setReportData(allResults);
            return allResults;
    
        } catch (err) {
            console.error("getReportData error>>>", err);
            setReportData([]);
            notifyError("Something went wrong!!");
        } finally {
            setLoading(false);
            setProgressBar(100); // Ensure the progress bar completes
        }
    };
    

    const [masterExpenseType, setMasterExpenseType] = useState([])
    const sortedBy = [
        { id: 1, value: "Requested", data: "Requested" },
        { id: 2, value: "Rejected", data: "Rejected" },
        { id: 3, value: "Approved", data: "Approved" },
        { id: 4, value: "Pending", data: "Pending" },
        { id: 5, value: "Employee Request", data: "Employee Request" },
        { id: 6, value: "HOD Request", data: "HOD Request" }
    ]

    const navigate = useNavigate();
    const navigateToDetails = (expenseID) => {
        const expenseIDs = filteredData?.map((item) => item?.id)
        navigate('/expenseDetails', { state: { data: expenseID, screen: "allRequest", expenseIDs: expenseIDs } })
    }


    const removeDuplicates = (arr) => {

        const uniqueValueArray = [];
        const seen = new Set();

        arr.forEach((value) => {
            const key = JSON.stringify(value);
            if (!seen.has(key)) {
                uniqueValueArray.push(value);
                seen.add(key);
            }
        });

        return uniqueValueArray;
    };

    
    const exportExcel = async () => {
        const reportDataFromApi = await getReportData();
        
        const columns = ["Date of Expense Submission", "Employee ID", "Employee Name", "Expense ID", "Start Date", "End Date", "Expense Type", "Sub Expense Type", "Description", "Start Location", "End Location", "Kms by Google", "Actual Kms Claimed", "Date From", "Date To", "No of Days", "Date", "Actual Amount Requested", "Approved Amount by HOD", "Approved Amount by Management", "Final Approved by HR"];
        const data = reportDataFromApi.map((item) => [`${item?.date_of_expense_submission}`, item?.employee_id, item?.employee_name, item?.expense_id, item?.start_date, item?.end_date, item?.expense_type, item?.sub_expense_type, item?.description, item?.start_location, item?.end_location, item?.kms_by_google, item?.actual_kms_claimed, item?.date_from, item?.date_to, item?.no_of_days, item?.date, item?.req_amount, item?.hod_approved_amount, item?.mgmnt_approved_amount, item?.hr_approved_amount]);

        return (
            ExportExcel(columns, data)
        )
    }

    const exportPdf = async () => {
        const reportDataFromApi = await getReportData();

        const groupedDataByUserId = reportDataFromApi.reduce((acc, item) => {
            const userId = item.employee_id;
            if (!acc[userId]) {
                acc[userId] = [];
            }
            acc[userId].push(item);
            return acc;
        }, {});

        const columns = ["Date of Expense Submission", "Employee ID", "Employee Name", "Expense ID", "Start Date", "End Date", "Expense Type", "Sub Expense Type", "Description", "Start Location", "End Location", "Kms by Google", "Actual Kms Claimed", "Date From", "Date To", "No of Days", "Date", "Actual Amount Requested", "Approved Amount by HOD", "Approved Amount by Management", "Final Approved by HR"];
        
        const doc = new jsPDF('landscape', 'mm', 'a3');
        doc.setFontSize(10);

        let userCounter = 0;
        for (const userId in groupedDataByUserId) {
            const userData = (groupedDataByUserId[userId] || []).filter(item => item.amount);

            if (userCounter++ > 0) {
                doc.addPage();
            }

            // Add user details
            const userDetails = {
                "Employee ID": userId
            };

            let index = 0;
            let contentHeight = 5;
            for (const key in userDetails) {
                contentHeight = contentHeight + index * 5;
                doc.text(`${key}: ${userDetails[key]}`, 5, contentHeight);
                index++;
            }

            const data = userData.map((item) => [`${item?.date}`, item?.expense_type, item?.sub_expense_type, item?.amount, (item?.hod_approved_amount || item?.amount), (item?.mgmnt_approved_amount || item?.amount), (item?.hr_approved_amount || item?.amount)]);

            const amountTotals = userData.reduce((acc, item) => {
                acc["total_amount_requested"] = parseFloat((acc.total_amount_requested || 0)) + parseFloat(item.amount)
                acc["total_amount_approved_by_hod"] = parseFloat((acc.total_amount_approved_by_hod || 0)) + parseFloat(item.hod_approved_amount || item.amount)
                acc["total_amount_approved_by_mngmt"] = parseFloat((acc.total_amount_approved_by_mngmt || 0)) + parseFloat(item.mgmnt_approved_amount || item.amount)
                acc["total_amount_approved_by_hr"] = parseFloat((acc.total_amount_approved_by_hr || 0)) + parseFloat(item.hr_approved_amount || item.amount)
                return acc;
            }, {});

            if (userData.length > 0) {
                data.push(["", "", "Total Rs :", amountTotals.total_amount_requested || "", amountTotals.total_amount_approved_by_hod || "", amountTotals.total_amount_approved_by_mngmt || "", amountTotals.total_amount_approved_by_hr || ""]);
            }

            autoTable(doc, {
                startY: contentHeight + 5,
                head: [columns],
                body: data,
                margin: {
                    left: 5,
                    right: 5,
                    top: 5,
                    bottom: 5
                },
                theme: "plain",
                headStyles: {
                    valign: "middle",
                    halign: "center",
                    lineWidth: 0.5,
                    lineColor: "#000"
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: "#000"
                },
            });
        }
        doc.save(`expenses.pdf`);
    };

    const [startDateFilter, setStartDate] = useState('')
    const [endDateFilter, setEndDate] = useState('')
    const [amountFilter, setAmountFilter] = useState('')

    const filterAmount = (e) => {
        setAmountFilter(e.target.value)
        setShowAll(false)
    }

    const startDate = (e) => {
        setStartDate(e.target.value)
        setShowAll(false)
    }

    const endDate = (e) => {
        setEndDate(e.target.value)
        setShowAll(false)
    }

    

    const filteredData = teamRequestDataCopy.filter(item => {
        const userIdmatch = selectedUserId ? item.user_id.includes(selectedUserId) : true;
        const expenseTypeMatch = filterType ? item.expense_type.includes(filterType) : true;
        const statusMatch = sortBy ? item.status === sortBy.toLocaleLowerCase() : true;
        const startDateMatch = startDateFilter
            ? new Date(item.created_at) >= new Date(startDateFilter)
            : true;
        const endDateMatch = endDateFilter
            ? new Date(item.created_at) <= new Date(endDateFilter)
            : true;

        const amountMatch = amountFilter ? parseFloat(item.total_amount) <= parseFloat(amountFilter) : true;
        return userIdmatch && expenseTypeMatch && statusMatch && startDateMatch && endDateMatch && amountMatch;
    });

    const clearFilter = () => {
        setSortBy('')
        setFilterType('')
        setStartDate('')
        setEndDate('')
        setAmountFilter('')
        setSelectedUserId('')
        setShowAll(true)
    }


    const [checkedRequest, setcheckedRequest] = useState([])

    const handleCheckbox = (e, itemdata) => {
        const newItem = {
            receipt_no: itemdata?.receipt_no,
            total_amount: itemdata?.total_amount,
        };

        if (e.target.checked) {
            // Check if the item already exists in the array
            const itemExists = checkedRequest.some(
                (item: any) =>
                    item.receipt_no === newItem.receipt_no &&
                    item.total_amount === newItem.total_amount
            );

            if (!itemExists) {
                // Item doesn't exist, add it to the array
                setcheckedRequest((prevList) => [...prevList, newItem]);
            } else {
                // console.log("Item already exists in the array.");
            }
        } else {
            // Remove the item from the array
            const updatedList = checkedRequest.filter(
                (item: any) => item.receipt_no !== newItem.receipt_no
            );
            setcheckedRequest(updatedList);
            // console.log(updatedList, "<<<<<employee_i");
        }
    };

    const handleSelectAll = (e, allData) => {

        if (e.target.checked) {
            // Check if the item already exists in the array
            const selectData = allData.filter((itemcheck) => itemcheck?.status == "approved").map((item) => {
                return {
                    receipt_no: item?.receipt_no,
                    total_amount: item?.total_amount,
                }
            })
            setcheckedRequest(selectData)
            // console.log(allData, "<<<allData");


        } else {
            // Remove the item from the array
            setcheckedRequest([])
        };
    }


    useEffect(() => {
        // console.log(checkedRequest, loginStatus?.role?.role, "<<<checkedRequest", window.location.pathname);
    }, [checkedRequest])

    const handlebulkAprove = async (data) => {

        setLoading(true)
        const formData = new FormData();
        formData.append('employee_id', loginStatus?.items[0]?.empcode);
        formData.append('expenses', JSON.stringify(data?.data?.expense));
        formData.append('remark', data?.remarks);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + "expense-bulk-approval.",
            headers: {},
            data: formData
        };
        await NewServiceCall(config).then((res) => {
            console.log(res, "<<<<<blueButtonStyle white");
            notifySuccess(res.data.messasge)
            window.location.reload()
            if (res.status == 200) {
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            notifyError(err.message)
        })

    }
    const handlePostTonav = async (data) => {

        const navToPostData = data?.map((item) => {
            return {
                receipt_no: item?.receipt_no || 0
            }
        })

        try {
            setLoading(true);
            
            const batchSize = 10; // Adjust batch size based on what your server can handle comfortably
            const expenseIdBatches = [];
    
            for (let i = 0; i < navToPostData.length; i += batchSize) {
                expenseIdBatches.push(navToPostData.slice(i, i + batchSize));
            }
    
            const totalBatches = expenseIdBatches.length;
            let completedBatches = 0;
            let allResults = [];
    
            for (const batch of expenseIdBatches) {
                const expenseIdParams = batch.map(item => item.receipt_no).join(',');
                console.log("expenseIdParams", expenseIdParams);
                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${initUrl}get-expense-amount-details?expense_id=${expenseIdParams}&status=approved`,
                    headers: {}
                };
                console.log(config.url, "<<<<navToPostData");
                const response = await NewServiceCall(config);
                if (response.status === 200) {
                    allResults = allResults.concat(response?.data?.result || []);
                } else {
                    console.error("Batch request failed with status:", response.status);
                    // Optionally, handle individual batch errors differently or retry logic
                }
    
                completedBatches++;
                const percentageComplete = (completedBatches / totalBatches) * 100;
                setProgressBar(percentageComplete); // Update your UI component that shows progress
            }
        } catch (err) {
            notifyError("Something went wrong!!");
        } finally {
            setLoading(false);
            setProgressBar(100); // Ensure the progress bar completes
        }
    
        
    }
    const [showViolation, setViolation] = useState(false)
    const [violationMetaData, setViolationMetaData] = useState({ remark: "", expense: checkedRequest })

    const violationDataHandle = (data) => {
        handlebulkAprove(data)
    }
    const setViolationFunction = (data) => {
        // console.log("policyData>>>", data)
        setTimeout(() => {
            setViolation(true)
        }, 500)
        setViolationMetaData({ expense: data })
    }




    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(5); // Change this to set the number of items per page
    // Example array of items to paginate
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) =>{
        setCurrentPage(pageNumber);
    }

    const handlepageEntry = (e) => {
        // console.log(e.target.value, "<<<<<enteried");
        setitemsPerPage(e.target.value)
        setCurrentPage(1)
    }



    return (
        <div>
            <LoadingSpinner loading={loading} />
            <div className='mt-20px'>
                {
                    teamRequestData.length !== 0 &&
                    <div className='m-10px textAlign-Start row mt-30px m-10px mb-1_5rem'>
                        <span className="bold1Rem commonBlackcolor">Team Member Expense Details - &nbsp;</span><span className="commonGraycolor light1Rem">{ExpenseDetails}</span>
                    </div>
                }
                <div>


                    {/*loginStatus?.role?.role === "HR" &&*/
                        locationData?.pathname == '/teamrequest/posted' ? "" :
                            <>
                                {
                                    true &&
                                    <div className="moduleBorderWithoutPadding d-flex row alignItem-center gap-20px">
                                        <div className='m-5px d-flex'>
                                            <input value="test" type="checkbox" onChange={handleChange} checked={showAll} />
                                            <span className="checkBoxText ml-5px">Show All</span>
                                        </div>
                                        <div>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <Select
                                                    value={""}
                                                    onChange={handleChangeSortBy}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Sort By:{sortBy}
                                                    </MenuItem>
                                                    {sortedBy.map((expense) => (
                                                        <MenuItem key={expense.id} value={expense.value}>
                                                            {expense.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <Select
                                                    value={""}
                                                    onChange={handleChangeUserId}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Employee:{selectedUserId}
                                                    </MenuItem>
                                                    {/* {teamRequestData
                                                        .map(el => el?.user_id)
                                                        .filter((el, i, arr) => arr.indexOf(el) === i) // Filter all unique user id
                                                        .map(userId => <MenuItem key={userId} value={userId}>{userId}</MenuItem>)
                                                    } */}
                                                    {teamRequestData
                                                        .map((el) => { return { user_id: el?.user_id, name: el?.name } })
                                                        .filter((el, i, arr) => arr.findIndex((item) => item?.user_id == el?.user_id) === i) // Filter all unique user id
                                                        .map(userId => <MenuItem key={userId?.user_id} value={userId?.user_id}>{userId?.user_id} : {userId?.name}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <Select
                                                    value={""}
                                                    onChange={handleChangeType}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Expense Type:{filterType}
                                                    </MenuItem>
                                                    {masterExpenseType.map((expense: any) => (
                                                        <MenuItem key={expense?.id} value={expense?.expense_name}>
                                                            {expense?.expense_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <TextField
                                                id={''}
                                                label={""}
                                                type={'date'}
                                                placeholder={`Select date here `}
                                                onChange={(e) => startDate(e)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={startDateFilter}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                id={''}
                                                label={""}
                                                type={'date'}
                                                placeholder={`Select date here `}
                                                onChange={(e) => endDate(e)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={endDateFilter}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                id={''}
                                                label={""}
                                                type={'Number'}
                                                placeholder={`Enter Amount`}
                                                onChange={(e) => filterAmount(e)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={amountFilter}
                                            />
                                        </div>
                                        <div className='d-flex m-10px'>
                                            <BlueCommonButton
                                                title={"Clear"}
                                                subTitle={""}
                                                buttonClick={() => {
                                                    clearFilter()
                                                }}
                                            />
                                        </div>

                                        {
                                            (loginStatus?.role?.role.includes("HR")) &&
                                            <div className='d-flex m-5px'>
                                                <button className="customBlueButtonStyle white"
                                                    disabled={checkedRequest.length > 0 ? false : true} onClick={() => { handlePostTonav(checkedRequest) }} >
                                                    <span className="ml-1px mr-1px d-flex"> Post to Nav </span></button>
                                            </div>
                                        }
                                    </div>
                                }
                                {(loginStatus?.role?.role.includes("HR")) &&
                                    <div className='d-flex row'>
                                        <div className='m-5px'>
                                            <WhiteCommonButton
                                                title={"Export as Excel"}
                                                subTitle={""}
                                                // buttonClick={() => ""}
                                                buttonClick={() => {
                                                        exportExcel()
                                                    
                                                }
                                                }
                                            />
                                        </div>
                                        <div className='m-5px'>
                                            <div className="d-flex curser">
                                                <div onClick={() => {
                                                        exportPdf()
                                                    
                                                }} className="blueButtonStyle white">
                                                    <span className="ml-10px mr-10px d-flex">Export as PDF</span>
                                                </div>
                                            </div>
                                            {/* <ExportPDFButton buttonText="Export as PDF" targetReff={targetRef} /> */}
                                        </div>
                                    </div>
                                }
                            </>
                    }
                      <div className='d-flex row show-entries-d p-10px '>
                      Entries :
                        <select onChange={handlepageEntry} value={itemsPerPage} >
                            <option>entries</option>
                            <option>5</option>
                            <option>10</option>
                            <option>50</option>
                            <option>100</option>
                        </select>
                    </div>
                </div>
                {loading && <ProgressBar progress={progress} />}
                {
                    teamRequestData.length !== 0 ?
                        <div ref={targetRef}>
                            <TableContainer component={Paper} id='teamRequest' >
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table">
                                    <TableHead>
                                        <TableRow>

                                            {(loginStatus?.role?.role.includes("HR")) &&
                                                // || loginStatus?.role?.role === "HOD") &&
                                                <TableCell align="center">
                                                    <div className="bold0_875Rem commonBlackcolor">
                                                        <input type="checkbox" name="selectall" onChange={(e) => {
                                                            handleSelectAll(e, filteredData)
                                                        }} />
                                                        Select all
                                                    </div>
                                                </TableCell>}
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Status</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Submission Date</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Expense ID</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Start Date</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">End Date</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Employee ID</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Employee Name</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Expense Type</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Expense Description</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Amount</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Approved Amount</div></TableCell>
                                            <TableCell align="center"><div className="bold0_875Rem commonBlackcolor">Action</div></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {filteredData.map((item) => ( */}
                                        {currentItems?.map((item) => (
                                            <TableRow
                                                key={item?.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                {
                                                    loginStatus?.role?.role.includes("HR") &&
                                                    <TableCell align="left">
                                                        <div className="d-flex row alignItem-center  justfyContent-center ">
                                                            {
                                                                item?.posted_to_nav == "0" && locationData?.pathname != '/teamrequest/posted' &&
                                                                //  item?.hr_approved == "no" && 
                                                                (
                                                                    <input type="checkbox" name="selectone" onChange={(e) => { handleCheckbox(e, item) }}
                                                                        checked={checkedRequest?.find((item2) => item2?.receipt_no == item?.receipt_no) ? true : false} />
                                                                )
                                                            }
                                                            {
                                                                item?.posted_to_nav == "1" &&
                                                                //  item?.hr_approved == "no" && 
                                                                (
                                                                    <input type="checkbox" name="selectone" onChange={(e) => { handleCheckbox(e, item) }}
                                                                        checked={checkedRequest?.find((item2) => item2?.receipt_no == item?.receipt_no) ? true : false} />
                                                                )
                                                            }
                                                        </div>
                                                    </TableCell>
                                                }


                                                <TableCell align="left">
                                                    <div className={`d-flex row  alignItem-center ${item.status === 'draft' ? 'draftCard' : item.status === 'pending' ? "pendingCard" : item.status === 'approved' ? 'approvalCard' : item.status === 'rejected' ? "rejectCard" : ""} justfyContent-center `}
                                                        // style={{ backgroundColor: item.status === 'draft' ? '#DBEEFF' : item.status === 'pending' ? '#FEF9C3' : item.status === 'rejected' ? '#FEE2E2' : item.status === 'approved' ? '#DCFCE7' : "" }}
                                                        style={{ padding: "1px 10px" }}
                                                    >
                                                        <div className={item.status === "draft" ? "dotBlue" : item.status === "approved" ? "dotGreen" : item.status === "rejected" ? "dotRed" : item.status === "pending" ? "dotOrange" : "dotBlue"}></div>
                                                        <div className="light0_875Rem commonGraycolor p-8px">{item?.status}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center" >
                                                    {/* <div className="light0_813Rem commonGraycolor">{item?.start_date}/{item?.end_date}</div> */}
                                                    <div className="light0_813Rem commonGraycolor">
                                                        <Moment format='DD MMM, HH:mm'>{item?.created_at}
                                                        </Moment>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" ><div className="regular-13px commonGraycolor">{item?.receipt_no}</div></TableCell>
                                                <TableCell component="th" scope="row" align="center" >
                                                    <div className="regular-13px commonGraycolor">{new Date(item?.start_date).toLocaleDateString('en-GB')}</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center" >
                                                    <div className="regular-13px commonGraycolor">{new Date(item?.end_date).toLocaleDateString('en-GB')}</div>
                                                </TableCell>
                                                <TableCell align="center" ><div className="light0_813Rem commonGraycolor">{item?.user_id}</div></TableCell>
                                                <TableCell align="center" ><div className="light0_813Rem commonGraycolor">{item?.name}</div></TableCell>
                                                <TableCell align="center" ><div className="light0_813Rem commonGraycolor">{item?.expense_type}</div></TableCell>
                                                <TableCell align="center"><div className="light0_813Rem commonGraycolor">{item?.description}</div></TableCell>
                                                <TableCell align="center"><div className="light0_813Rem commonGraycolor">{item?.total_amount !== null ? item?.total_amount : "---"}</div></TableCell>
                                                <TableCell align="center"><div className="light0_813Rem commonGraycolor">
                                                    {item?.hr_approved === 'yes' ?
                                                        `Hr Approved ${item?.approved_amount !== null || item?.approved_amount !== undefined ? item?.approved_amount : ""}`
                                                        : item?.mgmnt_approved === 'yes' ?
                                                            `Management Approved ${item?.mgmnt_approved_amount !== null || item?.mgmnt_approved_amount !== undefined ? item?.mgmnt_approved_amount : ""}`
                                                            : item?.hod_approved === 'yes' ?
                                                                `Hod Approved ${item?.hod_approved_amount !== null || item?.hod_approved_amount !== undefined ? item?.hod_approved_amount : ""}`
                                                                : "---"}</div></TableCell>
                                                <TableCell align="center">
                                                    <div className="d-flex row justfyContent-center alignItem-center curser">
                                                        <div className="d-flex row justfyContent-center alignItem-center" onClick={() => navigateToDetails(item.id)}>
                                                            <span className="commonGraycolor bold0_875Rem txtstyle curser">View details</span>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div>
                                <PeginationComponent
                                    itemsPerPage={itemsPerPage}
                                    totalItems={filteredData.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            </div>
                        </div>
                        :
                        !loading &&
                        <SearchFound />
                }

                {
                    showViolation &&
                    <ViolationPopup
                        close={(e) => { setViolation(false) }}
                        data={violationMetaData}
                        submit={(e: any) => {
                            // console.log('violoation datattatta?????????', e)
                            violationDataHandle(e)
                        }}
                    />
                }
            </div>
            {toastContainer()}
            
        </div>
    )
}