import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import cross from '../assets/images/cross.svg'
import { CancelCommonButton, BlueCommonButton, RedCommonButton } from './button.tsx'
import { Link, useNavigate } from 'react-router-dom';
import { setData, selectData,setLoginStatus } from '../Redux/features/login/loginSlicer'
import { useDispatch,useSelector } from 'react-redux';
import { expenseUrl } from "../service/url.js";
import { NewServiceCall } from "../service/config.js";
import { notifyError, notifySuccess, toastContainer } from "./toast.js";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
interface modelProps {
    close: () => void;
    expenseId : string;
    screenName : string;
}

export const DeletePopup: React.FC<modelProps> = ({close, expenseId, screenName}) => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDelete = async () => {
        const data = {
            expense_id: expenseId
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.deleteExpense,
            headers: {},
            data: data
        };
        setOpen(false);
        await NewServiceCall(config)
            .then((res) => {
                setOpen(false)
                if (res?.status === 200) {
                    notifySuccess(res?.data?.message)
                    navigate('/' + screenName)
                }
                else {
                    notifyError("Something went wrong!!")
                }
            })
            .catch((err) => {
                setOpen(false)
                notifyError("Something went wrong!!")
            })
            
            close()
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className='d-flex column logOutPopupStyle'>
                            <div className='d-flex row justfyContent-end'>
                                <img src={cross} style={{height:'30px', width:'30px'}} className='curser' onClick={close}/>
                            </div>
                            <div className='column d-flex'>
                                <span className='bold1Rem commonBlackcolor'>Are you sure you want to delete the expense ?</span>
                            </div>
                            <div className='d-flex justfyContent-end row'>
                                <div className='ml-20px '>
                                    <CancelCommonButton title="Cancel" buttonClick={()=> close()}/>
                                </div>
                                <div className='ml-20px mr-20px'>
                                    <RedCommonButton title="Yes" subTitle="" buttonClick={()=>handleDelete()}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}