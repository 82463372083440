import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import description from '../../assets/images/description.svg'
import closeArrow from '../../assets/images/closeArrow.svg'
import openArrow from '../../assets/images/openArrow.svg'
import map from '../../assets/images/travel/map.svg'
import parking from '../../assets/images/travel/parking.svg'
import toll from '../../assets/images/travel/toll.svg';
import distance from '../../assets/images/travel/distance.svg'
import amount from '../../assets/images/travel/amount.svg'
import city from '../../assets/images/city.svg'
import './expenseDetailHome.css'
import people from '../../assets/images/people.svg'

import send from '../../assets/images/send.svg'
import { BlueCommonButton, RedCommonButton, WhiteCommonButton, CancelCommonButton } from '../../components/button.tsx'
import { CustomeProgressBar } from "../../components/progressBar.jsx";
import { PolicyViolation } from "../../components/policyViolations.tsx";
import { TeamRequestScreen } from "../teamRequest/teamrequest.tsx";
import { RejectReason } from "../teamRequest/rejectReason.tsx";
import { RaiseQueryScreen } from "../teamRequest/raiseQuery.tsx";
import { MgmRequestScreen } from "../managementRequest/teamrequest.tsx";
import { MgmRejectReason } from "../managementRequest/rejectReason.tsx";
import { MgmRaiseQueryScreen } from "../managementRequest/raiseQuery.tsx";
import { AllRequestScreen } from "../allRequest/teamrequest.tsx";
import { AllRejectReason } from "../allRequest/rejectReason.tsx";
import { AllRaiseQueryScreen } from "../allRequest/raiseQuery.tsx";
import LoadingSpinner from "../../components/loader.tsx";
import { BrowserRouter as Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { admin, expenseQuery, expenseUrl, initUrl, main, violation } from "../../service/url.js";
import { useSelector, useDispatch } from 'react-redux';
import { toastContainer, notifyWarning, notifySuccess, notifyError } from '../../components/toast.js';
import { ServiceCall, NewServiceCall } from '../../service/config.js';
import { setData, selectData } from '../../Redux/features/login/loginSlicer';
import Tooltip from '@mui/material/Tooltip';
import i from '../../assets/images/i.svg'
import { RejectedExpenseDetails } from "../expense/rejectedDetails.tsx";
import { downloadImage } from "../../components/downloadFile.js";
import DropDownCustom from "../../components/customDropdown.js";
import { DeletePopup } from "../../components/deletePopUp.tsx";
import { ViewInvoices } from "../../components/viewInvoices.tsx";

const draftExpenseDetails = `Here are the details of your draft expenses. You can edit your information by clicking on the "edit" button and submit the expense by clicking the "submit" button.`
const draftExpenseDetailsTitle = 'Expense Details'
export const ExpenseDetailsHome = () => {
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const navigate = useNavigate();
    const { state } = useLocation();
    const loginStatus = useSelector(selectData);
    const [expenseID, setExpenseID] = useState(state?.data)
    const [finalAmount, setFinalAmount] = useState('')
    const [loading, setLoading] = useState(true)
    const [editable, setEditable] = useState(false)
    const [expenseDetails, setExpensedetails] = useState<any>([])
    const [showRequest, setShowRequest] = useState(false)
    const [partiallyApprove, setpartiallyApprove] = useState(false)
    const [raiseQuery, setRaiseQuery] = useState(false)
    const [reject, setReject] = useState(false)
    const [rejectedSelected, setrejectSelected] = useState(false)
    const [activeCard, setActiveCard] = useState('')
    const [mealDetails, setMealDetails] = useState<any>([{ id: Number, date: '', breakfast: '', lunch: '', dinner: '', file: '' }])
    const [travelDetails, setTravelDetails] = useState<any>([{ date: '', startLocation: '', endLocation: '', travelType: '', travelMode: '', parking: '', tollTax: '', actualDistance: '', calculatedDistance: '', amount: '', file: '' }])
    const [hotelDetails, setHotelDetails] = useState<any>([{ city: '', checkIn: '', checkOut: '', noOfDays: '', amount: '', file: '' }])
    const [daDetails, setDADetails] = useState<any>([{ date: '', city: '', amount: '' }])
    const [violation, setViolation] = useState([])
    const [status, setStatus] = useState("")
    const [otherData, setOtherData] = useState<any>([{ title: '', amount: '', remark: '' }])
    const [checkPartially, setcheckPartially] = useState(true)
    const renderTeamMembers = (members) => {
        const maxDisplayMembers = 1;
        if (members.length <= maxDisplayMembers) {
            return members.join(', ');
        } else {
            const displayedMembers = [members.slice(0, maxDisplayMembers)];
            const moreMembersCount = members.length - maxDisplayMembers;
            return (
                <>
                    {displayedMembers.join(', ')}
                    <div>+{moreMembersCount} more</div>
                </>
            );
        }
    };

    const handleDeleteButtonClick = () => {
        setShowDeletePopup(true);
    };

    const closeDeletePopup = () => {
        setShowDeletePopup(false);
    };

    const editButtonDraft = () => {
        navigate('/createExpense', { state: { data: expenseDetails } })
    }
    const handleTab = (tabName: string) => {
        setActiveCard(tabName === activeCard ? "" : tabName)
    }
    const handleMealDetails = (index: number, field: any, value: any) => {
        const updatedRows = [...mealDetails];
        updatedRows[index][field] = value
        setMealDetails(updatedRows)
    }

    const mealKeys = mealDetails?.length > 0 ? Object.keys(mealDetails[0]).filter(key => {
        if (mealDetails[0][`${key}_id`] != undefined) {
            return key
        }
    }) : []

    const updateMealRow = (id: number, value: any, field: string, index:any) => {
        // const updatedRows = mealDetails.map((row, rowIndex) => row.id === id ? { ...row, [field]: value } : row);
        // mealDetails[index][field] = value
        const updatedRows = [...mealDetails];
        updatedRows[index][field] = value
        setMealDetails(updatedRows)
        // setMealDetails(updatedRows)

        // console.log( "updating meal>>>>>>>" ,index,  mealDetails ,mealDetails[index][field],  );
        

    }
    const updatetravelRow = (id: number, value: any, field: string, invoice: any, invoiceEntry: any) => {
        const updatedRows = travelDetails.map((row, rowIndex) => row.id === id ? {
            ...row, ["invoices"]: [...row?.invoices?.map((invupdate) => {
                return {
                    ...invupdate,
                    amount: invupdate.id == invoice.id ? value : invupdate?.amount,

                }
            })
            ]
        } : row);
        setTravelDetails(updatedRows)
    }

    const updateHotelRow = (id: number, value: any, field: string, invoiceId: any) => {
        const encludedGstAmount = Number(value) + (Number(invoiceId?.gst_percentage) / 100) * Number(value)
        const updatedRows = hotelDetails.map((row, rowIndex) => row.id === id ?
            { ...row, [field]: value, "amount_withGst": encludedGstAmount, invoiceId: invoiceId?.id } :
            { ...row, invoiceId: row?.invoices[0]?.id, amount: row?.amount || row?.invoices[0]?.amount || 0, "amount_withGst": row?.amount_withGst || row?.invoices[0]?.amount_withGst || 0 });
        setHotelDetails(updatedRows)
    }




    const formateMealpayloadData = (data) => {
        return data.flatMap(entry => {
            return mealKeys.map(meal => {
                return {
                    expense_type: "meal",
                    meal_id: entry[`${meal}_id`],
                    id: entry[`${meal}_id`],
                    ex_date: entry.ex_date,
                    meal_name: meal.charAt(0).toUpperCase() + meal.slice(1),
                    amount: entry[meal],
                };
            });
        });
    };



    const updatePerticularExpense = async (type) => {
        setLoading(true)
        // mealtypes
        // console.log(formateMealpayloadData(mealDetails), mealDetails, "<<<<<<<<item");
        const mealFormat = formateMealpayloadData(mealDetails)
        const hotelFormat = hotelDetails?.map((item) => {
            return {
                id: item.invoiceId,
                expense_type: type,
                amount: item.amount,
                amount_withGst: item?.amount_withGst
            }
        })

        const travelFormat = travelDetails?.map((item) => {
            return {
                id: item?.invoices[0]?.id || 0,
                expense_type: type,
                amount: item?.invoices[0]?.amount || 0,
                [item?.invoices[1]?.ei_type]: item?.invoices[1]?.amount || 0,
                [item?.invoices[2]?.ei_type]: item?.invoices[2]?.amount || 0,
            }
        })
        const formData = new FormData();
        // console.log(mealDetails, "<<<mealDetails", travelFormat);
        if (type == "hotel") {
            formData.append("expensesData", JSON.stringify(hotelFormat))
        } else if (type == "meal") {
            formData.append("expensesData", JSON.stringify(mealFormat))
        } else if (type == "travel") {
            formData.append("expensesData", JSON.stringify(travelFormat))
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + "/api/edit-expense-data",
            headers: {},
            data: formData
        };
        await NewServiceCall(config).then((res) => {
            setLoading(false)
            if (res?.responseCode == 200) {
                notifySuccess(res.data?.message)
            }
            if (type == "travel") {
                // get_ExpenseDetail()
                window.location.reload()
            }
            // get_ExpenseDetail(state?.data)
            setEditable(false)
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        })
    }


    //************ Image download *************/

    const [invoiceView2, setinvoiceView] = useState(false)
    const [invoiceList, setinvoiceList] = useState([])
    // const [isloading, setloading] = useState(false)
    const handleImageDownload = (base64, fileName) => {
        // console.log(base64, "<<<base64", fileName);
        setinvoiceView(true)
        setinvoiceList(base64?.split(","))
    };
    const getFinalAmount = (data) => {
        const tempexpenseDetails = data[0]
        if (tempexpenseDetails?.hr_approved === "yes") {
            setFinalAmount(tempexpenseDetails?.approved_amount === null ? "" : tempexpenseDetails?.approved_amount);
        }
        else if (tempexpenseDetails?.mgmnt_approved === "yes") {
            setFinalAmount(tempexpenseDetails?.mgmnt_approved_amount === null ? "" : tempexpenseDetails?.mgmnt_approved_amount);
        }
        else if (tempexpenseDetails?.hod_approved === 'yes') {
            setFinalAmount(tempexpenseDetails?.hod_approved_amount === null ? "" : tempexpenseDetails?.hod_approved_amount);
        }
        else {
            setFinalAmount(tempexpenseDetails?.total_amount);
        }

        //console.log(tempexpenseDetails , "<<<<tempexpenseDetails");

    }

    useEffect(() => {
        get_ExpenseDetail(state?.data);
        // console.log(state?.screen, "screenName");
    }, [])

    const [rejectedData, setRejectedData] = useState()
    const get_rejectedData = async (preExpenseId: any) => {
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.expenseDetails,
            headers: {},
            params: { expense_id: preExpenseId }
        };
        await NewServiceCall(config)
            .then((result) => {
                setLoading(false)
                if (result?.status === 200) {
                    setRejectedData(result?.data?.result)
                }
                else {
                    setRejectedData(undefined)
                }
            })
            .catch((err) => {
                setLoading(false)
                setRejectedData(undefined)
                notifyError("Something went wrong!!")
                // console.log("get_ExpenseDetail reerrs>>>", err)
            })
    }
    const removeRemarks = (data) => {
        const mealsWithoutRemarks = data?.map((meal) => {
            const filteredMeal = Object.fromEntries(
                Object.entries(meal).filter(([key, _]) => !key.endsWith('_remark'))
            );
            return filteredMeal;
        });
        return mealsWithoutRemarks
    }
    const [finalCalculatedAmount, setFinalCalculatedAmount] = useState('')
    const [managmentApprovedA, setManagmentApprovedA] = useState(0)

    const calculateTotalSummeal = (meals, policyViolations) => {
        let mealNkey = Object.keys(meals[0]).filter(key => { if (meals[0][`${key}_id`] != undefined) { return key }})
        //    console.log(  "<<<<<<<meals",mealNkey  ,policyViolations);
        return meals?.reduce((total, meal) => {
            // console.log("data>." , meal);
            mealNkey?.forEach(mealType => {
                const violation = policyViolations?.find(v => { return v.record_id == meal[`${mealType}_id`] && v.subexpense_type?.toLowerCase() == mealType?.toLowerCase() });
                var amount = parseFloat(meal[mealType?.toLowerCase()]) || 0; // Default to 0 if not a number
                // Adjust the amount based on policy violations if any
                if (violation) {
                    // console.log(violation ,amount ,meal[mealType?.toLowerCase()]  );
                    amount = parseFloat(violation.hr_approved_amount) ||
                        //  parseFloat(violation.mgmnt_approved_amount) ||
                        parseFloat(violation.hod_approved_amount) || 0;
                }
                total += amount;
            });
            // console.log(total, "total");
            return total;
        }, 0);
    }

    const calculateTotalSumDa = (data) => {
        return data.reduce((sum, item) => {
            return sum + parseFloat(item.amount); // Use parseFloat to convert string amount to a number
        }, 0);
    }

    const calculateTotalSumDaTravel = (data, policyViolations) => {
        return data.reduce((totalSum, travel) => {
            // Sum all amounts in the invoices array of each travel, considering violations
            const travelSum = travel.invoices.reduce((sum, invoice) => {
                // Find the matching violation that corresponds to this invoice based on ei_id and that it is of type "amount"
                const violation = policyViolations.find(v =>
                    v.record_id === invoice.ei_id &&
                    v.subexpense_type === "amount" &&
                    invoice.ei_type === "amount"
                );

                // Determine the amount to add to the sum
                let amountToAdd;
                if (violation) {
                    // Use the first available approved amount from the hierarchy: HR, then Management, then HOD
                    amountToAdd = parseFloat(violation.hr_approved_amount) ||
                        parseFloat(violation.mgmnt_approved_amount) ||
                        parseFloat(violation.hod_approved_amount) || 0;
                } else {
                    // If no violation or no applicable approved amount, use the invoice's amount
                    amountToAdd = parseFloat(invoice.amount || '0');
                }

                return sum + amountToAdd;
            }, 0);

            return totalSum + travelSum;
        }, 0);
    }




    const calculateTotalHotelExpenses = (hotels, policyViolations) => {
        return hotels.reduce((totalSum, hotel) => {
            // Sum all amounts in the invoices array of each hotel, considering violations
            const hotelSum = hotel.invoices.reduce((sum, invoice) => {
                // Find the matching violation that corresponds to this invoice based on ei_id
                const violation = policyViolations.find(v =>
                    v.record_id === invoice.ei_id &&
                    v.subexpense_type === "amount" &&
                    invoice.ei_type === "amount"
                );

                // Determine the amount to add to the sum
                let amountToAdd;
                if (violation) {
                    // Use the first available approved amount from the hierarchy: HR, then Management, then HOD
                    amountToAdd = parseFloat(violation.hr_approved_amount) ||
                        parseFloat(violation.mgmnt_approved_amount) ||
                        parseFloat(violation.hod_approved_amount) || 0;
                } else {
                    // If no violation or no applicable approved amount, use the invoice's amount
                    const effectiveAmount = (invoice.amount_withGst !== null && invoice.amount_withGst !== undefined)
                        ? parseFloat(invoice.amount_withGst)
                        : parseFloat(invoice.amount || '0'); // Default to 0 if neither amount is defined
                    amountToAdd = effectiveAmount;
                }

                return sum + amountToAdd;
            }, 0);

            return totalSum + hotelSum;
        }, 0);
    }


    // console.log(state?.expenseIDs , "<<<<<<<state");



    const calculateTotalSumOther = (data) => {
        return data.reduce((sum, item) => {
            return sum + parseFloat(item.amount); // Use parseFloat to convert string amount to a number
        }, 0);
    }

    const handleExpenseNext = (cid, action) => {
        if (state?.expenseIDs) {
            let idsArray = state?.expenseIDs
            const findidIndex = idsArray.indexOf(expenseDetails[0]?.id)
            if (action == "next" && idsArray[idsArray.length - 1] != expenseDetails[0]?.id) {
                if (findidIndex != -1) {
                    get_ExpenseDetail(idsArray[findidIndex + 1])
                }
            } else if (action == "pre" && idsArray[0] != expenseDetails[0]?.id) {
                if (findidIndex != -1) {
                    get_ExpenseDetail(idsArray[findidIndex - 1])
                }
            }

            // console.log(findidIndex ,idsArray[idsArray.length-1], "<<<<<<<<<findidIndex" ,expenseDetails[0]?.id);
        }
    }

    const get_ExpenseDetail = async (id) => {
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.expenseDetails,
            headers: {},
            params: { expense_id: id }
        };
        await NewServiceCall(config)
            .then((result) => {
                setLoading(false)
                if (result?.status === 200) {
                    setExpensedetails(result?.data?.result)
                    setStatus(result?.data?.result[0]?.status)
                    setMealDetails(removeRemarks(result?.data?.result[0]?.meals))
                    // console.log("result?.data?.result[0]?>", calculateTotalSummeal(result?.data?.result[0]?.meals, result?.data?.result[0]?.policy_violations));
                    setTravelDetails(removeRemarks(result?.data?.result[0]?.travels))
                    setDADetails(removeRemarks(result?.data?.result[0]?.das))
                    setHotelDetails(removeRemarks(result?.data?.result[0]?.hotels))
                    setOtherData(result?.data?.result[0]?.others)
                    setViolation(result?.data?.result[0]?.policy_violations)

                    getFinalAmount(result?.data?.result)
                    //FIXME: Change prev_expense_id condition to int
                    if (result?.data?.result[0]?.prev_expense_id !== 0) {
                        get_rejectedData(result?.data?.result[0]?.prev_expense_id)
                    }
                    if (result?.data?.result[0]?.status == "rejected" && loginStatus.role?.employe_id.toString().toLowerCase() == result?.data?.result[0]?.user_id.toString().toLowerCase()) {
                        setrejectSelected(true)
                    } else {
                        setrejectSelected(false)
                    }
                    // console.log('login status::::??', loginStatus)
                    //**************** total amount in policy violation **************//
                    const totalAmount = result.data.result[0].policy_violations.reduce((acc, item) => {
                        return acc + parseFloat(item.amount);
                    }, 0);

                    //**************** total approved amount in policy violation **************//

                    const policyViolations = result.data.result[0].policy_violations;
                    let finalApprovedAmount = 0;
                    for (const item of policyViolations) {
                        //FIXME: change item status to int
                        if (Number(item.status) === 1 || Number(item.status) === 2 || Number(item.status) === 3) {
                            if ((item.hr_approved_type === "partial" || "full") && item.hr_approved_amount) {
                                finalApprovedAmount += parseFloat(item.hr_approved_amount);
                            } else if ((item.mgmnt_approved_type === "partial" || "full") && item.mgmnt_approved_amount) {
                                finalApprovedAmount += parseFloat(item.mgmnt_approved_amount);
                            } else if ((item.hod_approved_type === "partial" || "full") && item.hod_approved_type) {
                                finalApprovedAmount += parseFloat(item.hod_approved_amount);
                            }
                        }
                    }

                

                    const finalApprovedExpenseAmount = calculateTotalSummeal(result?.data?.result[0]?.meals, result?.data?.result[0]?.policy_violations)
                        + calculateTotalHotelExpenses(result?.data?.result[0]?.hotels, result?.data?.result[0]?.policy_violations)
                        + calculateTotalSumDaTravel(result?.data?.result[0]?.travels, result?.data?.result[0]?.policy_violations)
                        + calculateTotalSumDa(result?.data?.result[0]?.das) + calculateTotalSumOther(result?.data?.result[0]?.others);

                    // console.log(
                    //     "finalApprovedExpenseAmount",
                    //     finalApprovedExpenseAmount, 
                    //     calculateTotalSummeal(result?.data?.result[0]?.meals, result?.data?.result[0]?.policy_violations),
                    //     )

                    //**************** total amount after approved in policy violation **************//

                    const calculatedsubAmount = parseFloat(totalAmount) - (finalApprovedAmount)
                    const totalSubmittedAmount = result?.data?.result[0]?.total_amount

                    //**************** final amount **************//

                    const calculatedfinalAmount = parseFloat(totalSubmittedAmount) - calculatedsubAmount
                    setFinalCalculatedAmount(finalApprovedExpenseAmount);

                    // console.log(calculatedfinalAmount, "<<<<<<<calculatedfinalAmount");
                    //**************** check if any approved type is partial **************//
                    // console.log('calculatedfinalAmount', calculatedfinalAmount, totalSubmittedAmount)
                    if (parseFloat(finalApprovedExpenseAmount) !== parseFloat(totalSubmittedAmount)) {
                        setcheckPartially(false)
                    } else {
                        setcheckPartially(true)
                    }

                }
                else {
                    setExpensedetails([])
                    setStatus("")
                    setMealDetails([])
                    setTravelDetails([])
                    setDADetails([])
                    setHotelDetails([])
                    setOtherData([])
                    setViolation([])
                    getFinalAmount([])
                    get_rejectedData([])
                    notifyError("Something went wrong!!")
                }
            })
            .catch((err) => {
                setLoading(false)
                setExpensedetails([])
                setStatus("")
                setMealDetails([])
                setTravelDetails([])
                setDADetails([])
                setHotelDetails([])
                setOtherData([])
                setViolation([])
                getFinalAmount([])
                get_rejectedData([])
                notifyError("Something went wrong!!")
                // console.log("get_ExpenseDetail reerrs>>>", err)
            })
    }

    const formathotelData = hotelDetails?.map((hotel, index) => {
        const amountInvoice = hotel.invoices?.find((invoice) => invoice?.ei_type === 'amount');
        return {
            ...hotel,
            uid: index,
            type: 'hotel',
            city: hotel?.city || '',
            check_in_date: hotel?.check_in_date || '',
            check_out_date: hotel?.check_out_date || '',
            days_count: hotel?.days_count || '',
            amount: amountInvoice ? amountInvoice?.amount : '',
            invoice_file: amountInvoice ? amountInvoice?.invoice_file : '',
            hotel_tag: hotel?.hotel_tag || ''
        };

        // return hotel;
    });

    const mergedAllexpenseData = [...daDetails, ...otherData, ...travelDetails, ...formathotelData]
    const nonVoilatedExpense = mergedAllexpenseData?.map((item, index) => {
        let findisvoilated = violation.find((itemn) => itemn?.record_id == item?.id)
        if (findisvoilated == undefined) {
            return item
        }
    }).filter(item => item !== undefined)
    const nonVoilatedExpenseMeal = mealDetails?.map((item, index) => {
        let findisvoilated = violation.find((itemn) => itemn?.record_id == item?.id)
        if (findisvoilated == undefined) {
            return item
        }
    }).filter(item => item !== undefined)

    const newtotalAmount = nonVoilatedExpense.reduce((initialA, item) => {
        return initialA + Number(item?.amount) + (item?.parking ? Number(item?.parking[0]?.value ? item?.parking[0]?.value : 0) : 0) + (item?.toll ? Number(item?.toll[0]?.value ? item?.toll[0]?.value : 0) : 0)
    }, 0)// + calculateTotalSummeal(nonVoilatedExpenseMeal)

    const voilationTotalAmount = violation?.reduce((initialA, item) => {
        return initialA + Number(item?.hr_approved_amount ? item?.hr_approved_amount : item?.mgmnt_approved_amount ? item?.mgmnt_approved_amount : item?.hod_approved_amount)
    }, 0)


    // console.log(newtotalAmount,
    //     "<<nonVoilatedExpense >>nonVoilatedExpenseMeal", nonVoilatedExpenseMeal,
    //       finalCalculatedAmount,
    //     // mealDetails,
    //      voilationTotalAmount + newtotalAmount,
    //     "mealDetails<<<<<newtotalAmount", violation,
    //     loginStatus?.role?.role.split(',')?.includes('HR')
    //     )

    const [amountisEdit, setamountisEdit] = useState(false)

    setTimeout(() => {
        if (loginStatus?.role?.role.split(',')?.includes('HR') && amountisEdit) {
            setFinalCalculatedAmount(voilationTotalAmount + newtotalAmount)
        }
    }, 50);


    const [queryFromHr, setQueryFromHr] = useState("")
    const storedData = useSelector(setData);
    const userDetails = storedData?.payload?.login?.items[0]

    const submitExpense = async (status) => {

        const draftSubmitData = [mealDetails, travelDetails, hotelDetails, daDetails, otherData].filter(arr => arr.length > 0);
        const formData = new FormData();
        formData.append('user_id', userDetails?.empcode ? userDetails?.empcode : "");
        formData.append('expense_start_date', expenseDetails[0]?.start_date ? expenseDetails[0]?.start_date : "");
        formData.append('expense_end_date', expenseDetails[0]?.end_date ? expenseDetails[0]?.end_date : "");
        formData.append('expense_description', expenseDetails[0]?.description ? expenseDetails[0]?.description : "");
        formData.append('expense_total_amount', expenseDetails[0]?.total_amount?.toString());
        formData.append('expenses', JSON.stringify(draftSubmitData, null, 2).toString());
        formData.append('status', status)
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.addExpense,
            headers: {},
            data: formData
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res?.status === 200) {
                    notifySuccess(res?.data?.message)
                    navigate('./home')
                }
                else {
                    notifyError("Something went wrong!!")
                }

            })
            .catch((err) => {
                setLoading(false)
                notifyError("Something went wrong!!")
            })
    }
    const [queryData, setQueryData] = useState<any>(expenseDetails[0]?.tickets ? expenseDetails[0]?.tickets : [])
    const raiseQueryFromHR = async (expense_id, query_id) => {
        if (queryFromHr !== "") {
            setLoading(true)
            const url = initUrl + expenseQuery.createQuery
            const formData = new URLSearchParams();
            formData.append('expense_id', expense_id);
            formData.append('query_id', query_id);
            formData.append('sender_id', loginStatus?.items[0]?.empcode);
            formData.append('query_text', queryFromHr);
            await ServiceCall(url, formData, false)
                .then((res) => {
                    console.log("res>>", res)
                    if (res.responseCode === 200) {
                        notifySuccess(res?.message)
                        // getQueryTicketDetails(query_id)
                    }
                    else {
                        notifyError(res?.message)
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    notifyError("Something went wrong!!")
                    console.log("createQuery err>>", err)
                })
        }
        else {
            notifyError("Write a query")
        }
    }

    const filterKeys = (obj, keysToExclude) =>
        Object.fromEntries(
            Object.entries(obj).filter(([key]) => !keysToExclude.includes(key))
        );
    // Keys to exclude
    const excludedKeys = ['id', 'expense_id', 'updated_at', 'created_at',];
    const excludedTravelKeys = ['id', 'expense_id', 'updated_at', 'created_at', "invoices", "amount_remark"];

    const moveToResubmit = (id: number) => {
        navigate('/createExpense', { state: { data: expenseDetails, screenFrom: "rejected", rejecteddataid: id } })
        // navigate('/createExpense', { state: { data: expenseDetails } })
    }

    const moveToEDdit = () => {
        navigate('/createExpense', { state: { data: expenseDetails, screenFrom: "pending" } })
        // navigate('/createExpense', { state: { data: expenseDetails } })
    }

    const invoiceView = (value) => {
        return (
            <img
                style={{ height: '40px', objectFit: 'contain', margin: '10px' }}
                src={value}
                alt="Your SVG Image"
            />
        )
    }

    const [typeState, setType] = useState("")

    useEffect(() => {
        // if (loginStatus?.role?.role.includes("HR")) {
        //     if (violation.length > 0) {
        //         const hrApprovedAmount = violation?.reduce((initValue, item) => {
        //             return initValue + Number(item.hr_approved_amount || 0);
        //         }, 0);
        //         // setFinalCalculatedAmount(hrApprovedAmount)
        //         console.log("here violation violationviolationviolation>>", finalCalculatedAmount, hrApprovedAmount)
        //     }
        // }
    }, [partiallyApprove])


    // console.log(travelDetails, "<<<<<travelDetails");



    return (
        <div>
            {loading ? <div className="mainContainer"><LoadingSpinner loading={loading} /></div> :
                <div className='mt-20px'>
                    <>
                        {
                            //FIXME: Change prev_expense_id to int
                            Number(expenseDetails[0]?.prev_expense_id) !== 0 ?
                                <RejectedExpenseDetails data={rejectedData !== undefined ? rejectedData : []} />
                                :
                                ""
                        }
                    </>
                    {expenseDetails?.map((item, index) => (
                        <div key={index}>
                            <div className=' m-10px textAlign-Start row '>
                                <span className="bold1Rem commonBlackcolor">{draftExpenseDetailsTitle} - &nbsp;</span> <span className="commonGraycolor light1Rem">{draftExpenseDetails}</span>
                            </div>

                            {expenseDetails?.length == null || expenseDetails?.length === 0 ? //start,end,expense data 
                                <div className='moduleBorderWithoutPadding'>
                                    <span className="commonGraycolor light1Rem">{draftExpenseDetails}: No Data Found</span>
                                </div> :
                                <div className='moduleBorderWithoutPadding'>
                                    <div className='d-flex alignItem-start row' style={{ justifyContent: "space-between" }}>
                                        <div className="d-flex ">
                                            <div className='m-10px'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Start Date"
                                                    type='date'
                                                    value={item?.start_date}
                                                    disabled={editable ? false : true}
                                                    className='datepicker'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" className='ml-10px'>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                />
                                            </div>
                                            <div className='m-10px'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="End Date"
                                                    type='date'
                                                    disabled={editable ? false : true}
                                                    value={item?.end_date}
                                                    className='datepicker'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" className='ml-10px'>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                />
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "end",
                                            paddingRight: "10px"

                                        }}>
                                            <small className="m-0px">Receipt no : {item?.receipt_no} </small>
                                            <small>User ID : {item?.user_id}</small>
                                            <small className="m-0px">  , Expesne Id : {item?.id}</small>
                                        </div>
                                    </div>
                                    <div className='d-flex column m-10px'>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Expense Description"
                                            multiline
                                            maxRows={4}
                                            disabled={editable ? false : true}
                                            placeholder="Max 250 characters"
                                            value={item?.description}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={description} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                            }
                            <div>

                                {status === 'draft' || state?.screen === "teamRequest" ? null   //Progress Bar 
                                    :
                                    <div className="moduleBorderWithoutPadding pl-40px pr-40px" style={{ height: '20vh', marginBottom: "90px" }}>
                                        <div className="bold1Rem d-flex justfyContent-start">
                                            Process of Approval
                                        </div>
                                        <div className="pt-30px pl-20px pr-20px pb-25px">
                                            <CustomeProgressBar
                                                data={expenseDetails[0]}
                                            />
                                        </div>
                                    </div>
                                }
                                <>
                                    {expenseDetails[0]?.policy_violations.length === 0 ? null   //Policy violation
                                        :
                                        <div className="redModuleBorderWithoutPadding pl-40px pr-40px">
                                            <PolicyViolation
                                                data={violation}
                                                submitUser={expenseDetails[0]?.user_id}
                                                status={expenseDetails[0].status}
                                            />
                                        </div>
                                    }
                                </>
                                {mealDetails.length == null || mealDetails.length == 0  //meal card
                                    ? null :
                                    <div className="moduleBorderWithoutPadding ">
                                        <div onClick={() => handleTab("meal")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                            <span className={activeCard == "meal" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Meal Expense</span>
                                            <img src={activeCard == "meal" ? openArrow : closeArrow} />
                                        </div>
                                        <hr />
                                        <div>
                                            {
                                                activeCard == "meal" &&
                                                <>
                                                    {mealDetails?.map((entry, index) => {
                                                        const filteredEntry = filterKeys(entry, excludedKeys);
                                                        return (
                                                            <div key={index} className="ml-10px">
                                                                <div className="d-flex row aa flex-wrap">
                                                                    {Object?.entries(filteredEntry)?.map(([key, value]) => {
                                                                        const checkvoilationExist: any = violation.find((item: any) => item?.record_id == entry[`${item?.subexpense_type?.toLowerCase()}_id`])
                                                                        // console.log(violation ,checkvoilationExist ,checkvoilationExist?.subexpense_type?.toLowerCase() , key  );
                                                                        return (<div key={key}>
                                                                            {value !== "" && value !== null && key !== "invoice_file"
                                                                                && !key.includes("_name")
                                                                                && !key.includes("_invoice_file")
                                                                                && !key.includes("_id") ? (
                                                                                <div className="bb">
                                                                                    <div className='m-10px'>
                                                                                        <TextField
                                                                                            id="input-with-icon-textfield"
                                                                                            label={key === "ex_date" ? "Date" : key.charAt(0).toUpperCase() + key.slice(1)?.replace(/_/g, " ")}
                                                                                            type={key === "ex_date" ? 'date' : "string"}
                                                                                            defaultValue={key.includes("_tag") ? value.split(',')[0] : value}
                                                                                            disabled={(editable && !(checkvoilationExist?.subexpense_type?.toLowerCase() == key)) ? false : true}
                                                                                            className='datepicker'
                                                                                            InputProps={{
                                                                                                startAdornment: (
                                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                updateMealRow(entry?.id, Number(e.target.value), key?.toLowerCase() , index);
                                                                                            }}
                                                                                            variant="standard"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            {
                                                                                value !== "" && value !== null && key.includes("_invoice_file") ?
                                                                                    (
                                                                                        <div className='m-10px d-flex alignItem-center'>
                                                                                            <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(value, 'file')}>
                                                                                                View {value ? key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1) : null}
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {key.includes("_tag") && value !== "" ? (
                                                                                <div className='m-5px light0_875Rem d-flex'>
                                                                                    <Tooltip
                                                                                        title={`Tagged members: ${value}`} arrow>
                                                                                        <div className="d-flex">
                                                                                            <span className='m-5px light0_875Rem '>
                                                                                                Tag: {renderTeamMembers(value?.split(',')?.map(member => member.trim()))}
                                                                                            </span>
                                                                                            <img src={i} className='iImg m-5px' />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            ) : null}

                                                                        </div>)
                                                                    }

                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {
                                                        loginStatus?.role?.role.includes('HR') &&
                                                        <div className="ml-15px">
                                                            {
                                                                editable ?
                                                                    <BlueCommonButton
                                                                        title={"Save"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            // setEditable(true)
                                                                            updatePerticularExpense("meal")
                                                                        }}
                                                                    /> :
                                                                    <BlueCommonButton
                                                                        title={"Edit"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            setamountisEdit(true)
                                                                            setEditable(true)
                                                                        }}
                                                                    />
                                                            }
                                                        </div>

                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                {travelDetails.length == null || travelDetails.length == 0  //travel card 
                                    ? null :
                                    <div className="moduleBorderWithoutPadding ">
                                        <div onClick={() => handleTab("travel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                            <span className={activeCard == "travel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Travel Expense</span>
                                            <img src={activeCard == "travel" ? openArrow : closeArrow} />
                                        </div>
                                        <hr />
                                        <div className='d-flex column pl-20px pr-20px' >
                                            {
                                                activeCard == "travel" &&
                                                <>
                                                    {
                                                        travelDetails?.map((entry, index) => {
                                                            const filteredEntry = filterKeys(entry, excludedTravelKeys);
                                                            // console.log(entry, "<<<<<filteredEntry");
                                                            const findInvoice = entry?.invoices.find((item) => item?.ei_type == "amount")
                                                            const checkvoilationExist: any = violation.find((item: any) => item?.record_id == entry?.id)
                                                            return (
                                                                <div key={index}>
                                                                    <div className="d-flex row aa flex-wrap">
                                                                        {Object.entries(filteredEntry)?.map(([key, value]) => (
                                                                            <div key={key}>
                                                                                {value !== "" && value !== null ? (
                                                                                    <div className="bb new-text-class">
                                                                                        <div className='m-10px'>
                                                                                            <TextField
                                                                                                id="input-with-icon-textfield"
                                                                                                label={key.replace(/_/g, ' ').split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                                                                type={key === "travel_date" ? 'date' : "string"}
                                                                                                value={value}
                                                                                                disabled={editable ? false : true}
                                                                                                className='datepicker'
                                                                                                InputProps={{
                                                                                                    startAdornment: (
                                                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                                                        </InputAdornment>
                                                                                                    ),
                                                                                                }}
                                                                                                variant="standard"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        ))}
                                                                        {
                                                                            entry.invoices[0]?.has_base_location &&
                                                                            <div className="m-2px ">
                                                                                <DropDownCustom
                                                                                    icon={<img src={people} />}
                                                                                    lable="Base Location Included"
                                                                                    data={[{ id: 1, name: "Yes" }, { id: 1, name: "No" }]}
                                                                                    selectedValue={entry.invoices[0]?.has_base_location ? entry.invoices[0]?.has_base_location : ""}
                                                                                    edittype="hasBaseEdit"
                                                                                    setValue={(e) => {
                                                                                        // baselocationCal(e?.name, dataValue.actual_distance, dataValue.calculated_distance, dataValue.uid, item)
                                                                                        // updateTravelDetails("has_base_location", e?.name, dataValue.uid)
                                                                                    }}
                                                                                    viewKeyName="name"
                                                                                />
                                                                            </div>

                                                                        }
                                                                    </div>




                                                                    {entry.invoices && entry.invoices?.map((invoice) => (
                                                                        <div key={invoice.id}>
                                                                            <div className="d-flex row alignItem-center flex-wrap">
                                                                                <div className='m-10px d-flex'>
                                                                                    <TextField
                                                                                        id="input-with-icon-textfield"
                                                                                        label={invoice.ei_type === 'parking' ? "Parking" : invoice.ei_type === 'amount' ? "Amount" : " Toll Amount"}
                                                                                        type='number'
                                                                                        disabled={(editable && !checkvoilationExist) ? false : true}
                                                                                        placeholder='Amount'
                                                                                        defaultValue={invoice.amount}
                                                                                        onChange={(e) => {
                                                                                            updatetravelRow(entry.id, e.target.value, "amount", invoice, entry?.invoices)
                                                                                            // console.log(entry, invoice);
                                                                                        }
                                                                                        }
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    invoice?.invoice_file === null || invoice?.invoice_file === "" ? "" :
                                                                                        <div className='m-10px d-flex alignItem-center'>
                                                                                            <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                                View Invoice File
                                                                                            </span>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {
                                                                        findInvoice?.bording_pass &&
                                                                        <div className='m-10px d-flex alignItem-center'>
                                                                            <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(findInvoice?.bording_pass, 'file')}>
                                                                                View Boarding Pass
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        loginStatus?.role?.role.includes('HR') &&
                                                        <div className="ml-15px">
                                                            {
                                                                editable ?
                                                                    <BlueCommonButton
                                                                        title={"Save"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            // setEditable(true)
                                                                            updatePerticularExpense("travel")
                                                                        }}
                                                                    /> :
                                                                    <BlueCommonButton
                                                                        title={"Edit"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            setEditable(true)
                                                                        }}
                                                                    />
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                {hotelDetails.length === null || hotelDetails.length === 0 ? null :    //hotel card 
                                    <div className="moduleBorderWithoutPadding">
                                        <div onClick={() => handleTab("hotel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                            <span className={activeCard == "hotel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Hotel Expense</span>
                                            <img src={activeCard == "hotel" ? openArrow : closeArrow} />
                                        </div>
                                        <hr />
                                        <div className='d-flex column pl-20px pr-20px' >
                                            {
                                                activeCard == "hotel" &&
                                                <>
                                                    {
                                                        hotelDetails?.map((item, id) => {
                                                            const checkvoilationExist: any = violation.find((newItem: any) => newItem?.record_id == Number(item?.id))
                                                            return (<div className='d-flex row alignItem-center flex-wrap'>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="City"
                                                                        type='string'
                                                                        disabled={editable ? false : true}
                                                                        placeholder='Enter amount'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={city} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        value={item?.city}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Check In"
                                                                        type='date'
                                                                        value={item?.check_in_date}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Check Out"
                                                                        type='date'
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        value={item?.check_out_date}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Number of days"
                                                                        value={item?.days_count}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                {
                                                                    item?.invoices && item.invoices?.map((invoice) => (
                                                                        <>
                                                                            <div className='m-10px' key={invoice.id}>
                                                                                <TextField
                                                                                    id="input-with-icon-textfield"
                                                                                    label="Amount (Exclusive GST)"
                                                                                    type='number'
                                                                                    defaultValue={invoice?.amount ? invoice?.amount : ""}
                                                                                    disabled={(editable && !checkvoilationExist) ? false : true}
                                                                                    placeholder='Select location'
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <img src={amount} />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice)
                                                                                        // console.log("<<<hotelDetails", item?.amount_withGst);
                                                                                    }}
                                                                                    // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                    variant="standard"
                                                                                />
                                                                            </div>
                                                                            <div className='m-10px' key={invoice.id}>
                                                                                <TextField
                                                                                    id="input-with-icon-textfield"
                                                                                    label={`Amount (Inclusive GST @${invoice?.gst_percentage}%)`}
                                                                                    type='number'
                                                                                    value={item?.amount_withGst ? item?.amount_withGst : invoice?.amount_withGst}
                                                                                    defaultValue={invoice?.amount_withGst ? invoice?.amount_withGst : ""}
                                                                                    disabled={editable ? false : true}
                                                                                    placeholder='Select location'
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <img src={amount} />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        console.log("<<<hotelDetails", item);
                                                                                        updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice.id)

                                                                                    }}
                                                                                    // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                    variant="standard"
                                                                                />
                                                                            </div>
                                                                            {
                                                                                invoice?.invoice_file !== null ?
                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                        <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                            View Invoice File
                                                                                        </span>
                                                                                    </div>
                                                                                    : ""
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    item?.hotel_tag !== "" ?
                                                                        <>
                                                                            <div className='m-5px light0_875Rem d-flex'>
                                                                                <Tooltip
                                                                                    title={`Tagged members: ${item?.hotel_tag}`} arrow>
                                                                                    <div className="d-flex">
                                                                                        <span className='m-5px light0_875Rem '>
                                                                                            Tag: {renderTeamMembers(item?.hotel_tag?.split(',')?.map(member => member.trim()))}
                                                                                        </span>
                                                                                        <img src={i} className='iImg m-5px' />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>

                                                                        </> : ""
                                                                }


                                                            </div>)
                                                        })}
                                                    {
                                                        loginStatus?.role?.role.includes('HR') &&
                                                        <div className="ml-15px">
                                                            {
                                                                editable ?
                                                                    <BlueCommonButton
                                                                        title={"Save"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            // setEditable(true)
                                                                            updatePerticularExpense("hotel")
                                                                        }}
                                                                    /> :
                                                                    <BlueCommonButton
                                                                        title={"Edit"}
                                                                        subTitle={""}
                                                                        buttonClick={() => {
                                                                            setEditable(true)
                                                                        }}
                                                                    />
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }

                                {daDetails.length === null || daDetails.length === 0 ? null :   // da card 
                                    <div className="moduleBorderWithoutPadding">
                                        <div onClick={() => handleTab("da")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                            <span className={activeCard == "da" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>DA Expense</span>
                                            <img src={activeCard == "da" ? openArrow : closeArrow} />
                                        </div>
                                        <hr />
                                        <div className='d-flex column pl-20px pr-20px' >
                                            {
                                                activeCard == "da" &&
                                                <>
                                                    {daDetails?.map((item, id) =>
                                                        <div className='d-flex row alignItem-center flex-wrap' key={id}>
                                                            <div className='m-10px'>
                                                                <TextField
                                                                    id="input-with-icon-textfield"
                                                                    label="Date"
                                                                    type='date'
                                                                    disabled={editable ? false : true}
                                                                    value={item?.ex_date}
                                                                    className='datepicker'
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    variant="standard"
                                                                />
                                                            </div>
                                                            <div className='m-10px'>
                                                                <TextField
                                                                    id="input-with-icon-textfield"
                                                                    label="City"
                                                                    disabled={editable ? false : true}
                                                                    value={item?.city}
                                                                    type='string'
                                                                    placeholder='Enter amount'
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <img src={city} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    variant="standard"
                                                                />
                                                            </div>
                                                            <div className='m-10px'>
                                                                <TextField
                                                                    id="input-with-icon-textfield"
                                                                    label="Amount"
                                                                    type='number'
                                                                    disabled={editable ? false : true}
                                                                    value={item?.amount}
                                                                    placeholder='Select location'
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <img src={amount} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    variant="standard"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                {otherData.length === null || otherData.length === 0 ? null :    //hotel card 
                                    <div className="moduleBorderWithoutPadding">
                                        <div onClick={() => handleTab("other")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                            <span className={activeCard == "other" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Other Expense</span>
                                            <img src={activeCard == "other" ? openArrow : closeArrow} />
                                        </div>
                                        <hr />
                                        <div className='d-flex column pl-20px pr-20px' >
                                            {
                                                activeCard === "other" &&
                                                <>
                                                    {/* {console.log('othet dadaddadd?????????', activeCard, otherData)} */}
                                                    {
                                                        otherData?.map((item, id) =>
                                                            <div className='d-flex row alignItem-center flex-wrap'>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Date"
                                                                        type='date'
                                                                        disabled={editable ? false : true}
                                                                        value={item?.date}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Title"
                                                                        type='string'
                                                                        disabled={editable ? false : true}
                                                                        placeholder='Enter title'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={city} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        value={item?.title}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                {
                                                                    item?.lunch_day_count &&
                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Number of days"
                                                                            type='string'
                                                                            disabled={editable ? false : true}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src={city} />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            value={item?.lunch_day_count}
                                                                            variant="standard"
                                                                        />
                                                                    </div>
                                                                }


                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Amount"
                                                                        value={item?.amount}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>

                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Remark"
                                                                        value={item?.remark}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>

                                                                <div className="m-10px">
                                                                    {
                                                                        item?.invoice_file !== null ?
                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(item?.invoice_file, 'file')}>
                                                                                    View Invoice File
                                                                                </span>
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>



                            {status == 'draft' ? null :
                                <>
                                    {
                                        expenseDetails[0]?.tickets && expenseDetails[0]?.tickets?.length > 0 &&
                                        <>
                                            <div className="m-10px bottomQuery p-1_5rem">
                                                {expenseDetails[0]?.tickets?.map((query, id) =>
                                                    <>
                                                        <div className="m-20px">
                                                            <div className="d-flex curser mainbgColor radius w-15per p-5px alignItem-center">
                                                                <span className="white regular0_65Rem ">{`A query from : ${query?.sender_id ? query?.sender_id : ""}`}</span>
                                                            </div>
                                                        </div>
                                                        <div className=' m-10px textAlign-Start row'>
                                                            <span className="light0_813Rem">{`query : ${query?.query ? query?.query : ""}`}</span>
                                                        </div>
                                                    </>
                                                )}
                                                {
                                                    expenseDetails[0]?.tickets[expenseDetails[0]?.tickets?.length - 1]?.status !== "close" ?
                                                        <div className="justfyContent-start d-flex row alignItem-center">
                                                            <div className="w-100per">
                                                                <input
                                                                    type="text"
                                                                    // value={queryFromHr}
                                                                    onChange={(e) => setQueryFromHr(e.target.value)}
                                                                    className="w-100per p-15px queryinput"
                                                                    placeholder="Write your message here"
                                                                />
                                                            </div>
                                                            <div className="sendBtn" onClick={() => raiseQueryFromHR(expenseDetails[0]?.id, 0)}>
                                                                <img src={send} />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {
                                rejectedSelected &&
                                <div className="m-10px redcolor p-1_5rem radius">
                                    <div className="m-10px">
                                        <RedCommonButton
                                            title={"Reason of Rejection"}
                                            subTitle={""}
                                            buttonClick={(e: any) => console.log('Reason of Rejection')}
                                        />
                                    </div>
                                    <div className=' m-10px textAlign-Start row'>
                                        <span className="light0_813Rem">{expenseDetails[0].rejected_reason}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}

                    {/* footer */}
                    {expenseDetails[0]?.status === "draft" &&
                        <div className='d-flex row flex-wrap'>
                            <div className='m-10px'>
                                <CancelCommonButton
                                    title={"Cancel"}
                                    buttonClick={() => navigate('/draft')}
                                />
                            </div>
                            <div className='m-10px'>
                                {
                                    status === 'draft' ? "" :
                                        <BlueCommonButton
                                            title={"Save for later"}
                                            subTitle={""}
                                            buttonClick={() => status === 'draft' ? submitExpense('submit') : submitExpense('draft')}
                                        />
                                }
                            </div>
                            {!editable &&
                                <div className='m-10px'>
                                    <WhiteCommonButton
                                        title={status === 'draft' ? 'Edit' : "Submit"}
                                        subTitle={""}
                                        buttonClick={() => editButtonDraft()}
                                    />
                                </div>
                            }
                            {!showDeletePopup && (
                                <div className='m-10px'>
                                    <RedCommonButton
                                        title={'Delete'}
                                        subTitle={""}
                                        buttonClick={handleDeleteButtonClick}
                                    />
                                </div>
                            )}
                            {showDeletePopup && (
                                <DeletePopup close={closeDeletePopup} expenseId={expenseDetails[0].id} screenName="draft" />
                            )}
                        </div>
                    }
                    {(expenseDetails[0]?.status === "pending" && expenseDetails[0]?.hod_approved === "no" && loginStatus?.role?.employe_id?.toString().toLowerCase() === expenseDetails[0]?.user_id.toString().toLowerCase()) &&
                        <div className='d-flex row flex-wrap'>
                            <div className='m-10px'>
                                <CancelCommonButton
                                    title={"Cancel"}
                                    buttonClick={() => navigate('/pending')}
                                />
                            </div>

                            {!editable &&
                                <div className='m-10px'>
                                    <WhiteCommonButton
                                        title={status === 'pending' ? 'Edit' : "Submit"}
                                        subTitle={""}
                                        buttonClick={() => moveToEDdit()}
                                    />
                                </div>
                            }
                            {!showDeletePopup && (
                                <div className='m-10px'>
                                    <RedCommonButton
                                        title={'Delete'}
                                        subTitle={""}
                                        buttonClick={handleDeleteButtonClick}
                                    />
                                </div>
                            )}

                            {showDeletePopup && (
                                <DeletePopup close={closeDeletePopup} expenseId={expenseDetails[0].id} screenName="pending" />
                            )}
                        </div>
                    }

                    {
                        rejectedSelected && expenseDetails[0]?.prev_expense_id != -1 &&
                        <div className='m-10px'>
                            <BlueCommonButton
                                title={"Click here to resubmit"}
                                subTitle={""}
                                buttonClick={(e: any) => moveToResubmit(expenseDetails[0].id)}
                            />
                        </div>
                    }
                    {
                        (state?.screen === "teamRequest" || state?.screen === "allRequest") && loginStatus?.role?.role.split(',')?.includes('HR') &&
                        <div className='d-flex row p-10px  flex-wrap' style={{ justifyContent: "space-between" }} >
                            {/* <div className="d-flex row flex-wrap gap-10px alignItem-center" style={{ justifyContent: "space-between" }}> */}
                            <BlueCommonButton
                                title={"Previous"}
                                subTitle={""}
                                // buttonClick={(e: any) => setReject(true)}
                                buttonClick={(e: any) => handleExpenseNext(state?.data, "pre")}
                            />
                            <BlueCommonButton
                                title={"Next"}
                                subTitle={""}
                                buttonClick={(e: any) => handleExpenseNext(state?.data, "next")}
                            />
                            {/* </div> */}
                        </div>
                    }

                    {
                        (state?.screen === "teamRequest" || state?.screen === "allRequest") &&
                        <>
                            {expenseDetails[0]?.status === 'pending' &&
                                <div className='d-flex row  flex-wrap' style={{ justifyContent: "space-between" }}>
                                    <div className="d-flex row flex-wrap">
                                        {
                                            (((loginStatus?.role?.role.split(',')?.includes('HOD')) && (loginStatus?.role?.role.split(',')?.includes('Japaness')) && (expenseDetails[0]?.hod_approved === 'no') && (expenseDetails[0]?.mgmnt_approved === 'yes'))
                                            ) ?
                                                '' : (
                                                    <>

                                                        {
                                                            (((loginStatus?.role?.role.split(',')?.includes('HOD')) && (expenseDetails[0]?.hod_approved === 'no')) ||
                                                                ((loginStatus?.role?.role.split(',')?.includes('HR')) && (expenseDetails[0]?.hr_approved === 'no')) ||
                                                                ((loginStatus?.role?.role.split(',')?.includes('Japaness')) && (expenseDetails[0]?.mgmnt_approved === 'no'))
                                                            ) &&
                                                            <>

                                                                {(
                                                                    (loginStatus?.role?.role.split(',')?.includes('HOD') && expenseDetails[0]?.hod_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.hod_approved_type == null || "" || undefined)) ||
                                                                    (loginStatus?.role?.role.split(',')?.includes('HR') && expenseDetails[0]?.hr_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.hr_approved_type == null || "" || undefined)) ||
                                                                    (loginStatus?.role?.role.split(',')?.includes('Japaness') && expenseDetails[0]?.mgmnt_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.mgmnt_approved_type == null || "" || undefined))
                                                                ) ? (
                                                                    <>
                                                                        <div className='m-10px'>
                                                                            <BlueCommonButton
                                                                                title={"Approve Request"}
                                                                                subTitle={""}
                                                                                buttonClick={(e: any) => {
                                                                                    setShowRequest(true);
                                                                                    setType("Approve Request");
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <BlueCommonButton
                                                                                title={"Partially Approve Request"}
                                                                                subTitle={""}
                                                                                buttonClick={(e: any) => {
                                                                                    setpartiallyApprove(true)
                                                                                    setType("Partially Approve Request")
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        (loginStatus?.role?.role.split(',')?.includes('HOD') && expenseDetails[0]?.hod_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.hod_approved_type == 'full')) ||
                                                                        (loginStatus?.role?.role.split(',')?.includes('HR') && expenseDetails[0]?.hr_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.hr_approved_type == 'full')) ||
                                                                        (loginStatus?.role?.role.split(',')?.includes('Japaness') && expenseDetails[0]?.mgmnt_approved === 'no' && expenseDetails[0]?.policy_violations.every((item) => item?.mgmnt_approved_type == 'full'))
                                                                    ) ? (
                                                                        <div className='m-10px'>
                                                                            <BlueCommonButton
                                                                                title={"Approve Request"}
                                                                                subTitle={""}
                                                                                buttonClick={(e: any) => {
                                                                                    if (!editable) {
                                                                                        setShowRequest(true);
                                                                                        setType("Approve Request");
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : <div className='m-10px'>
                                                                        <BlueCommonButton
                                                                            title={"Partially Approve Request"}
                                                                            subTitle={""}
                                                                            buttonClick={(e: any) => {
                                                                                if (!editable) {
                                                                                    setpartiallyApprove(true)
                                                                                    setType("Partially Approve Request")
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    expenseDetails[0]?.tickets.length === 0 ?
                                                                        <>
                                                                            <div className='m-10px'>
                                                                                <BlueCommonButton
                                                                                    title={"Raise a Query"}
                                                                                    subTitle={""}
                                                                                    buttonClick={(e: any) => setRaiseQuery(true)}
                                                                                />
                                                                            </div>
                                                                        </> : ""
                                                                }

                                                                <div className='m-10px'>
                                                                    <BlueCommonButton
                                                                        title={"Reject Request"}
                                                                        subTitle={""}
                                                                        buttonClick={(e: any) => setReject(true)}
                                                                    />
                                                                </div>

                                                            </>}
                                                    </>
                                                )
                                        }
                                    </div>

                                </div>
                            }
                            {
                                expenseDetails[0]?.status === 'rejected' ? "" :
                                    <>
                                        <div className='m-10px mainbgColor approve'>
                                            <div className=''>
                                                <span className="white regular0_875Rem">
                                                    {expenseDetails[0]?.hr_approved == 'yes' ?
                                                        "HR Approved Amount : " : expenseDetails[0]?.mgmnt_approved == 'yes'
                                                            ? "Management Approved Amount : " : expenseDetails[0]?.hod_approved == 'yes' ? "Hod Approved Amount : "
                                                                : "Total Submitted Amount : "
                                                    }
                                                    {expenseDetails[0]?.hr_approved == 'yes'
                                                        ? expenseDetails[0]?.approved_amount
                                                        : expenseDetails[0]?.mgmnt_approved == 'yes'
                                                            ? expenseDetails[0]?.mgmnt_approved_amount
                                                            : expenseDetails[0]?.hod_approved == 'yes'
                                                                ? expenseDetails[0]?.hod_approved_amount
                                                                : expenseDetails[0]?.total_amount
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='m-10px mainbgColor approve'>
                                            <div className='space-around'>
                                                <span className="white regular0_875Rem ">
                                                    {`Total submitted Amount : ${expenseDetails[0]?.total_amount ? expenseDetails[0]?.total_amount : "---"}`}
                                                </span>
                                                <span className="white regular0_875Rem ml-20px">
                                                    {`HOD Approved Amount : ${expenseDetails[0]?.hod_approved_amount ? expenseDetails[0]?.hod_approved_amount : "---"}`}
                                                </span>
                                                <span className="white regular0_875Rem ml-20px">
                                                    {`Mngmnt Approved Amount : ${expenseDetails[0]?.mgmnt_approved_amount ? expenseDetails[0]?.mgmnt_approved_amount : "---"}`}
                                                </span>
                                                <span className="white regular0_875Rem ml-20px">
                                                    {`HR Approved Amount : ${expenseDetails[0]?.approved_amount ? expenseDetails[0]?.approved_amount : "---"}`}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                    }
                    {
                        <>
                            {loginStatus?.role?.role.split(',').includes('Japaness') ? (
                                // Priority to 'Japaness' role if present
                                <>
                                    {showRequest &&
                                        <MgmRequestScreen
                                            close={() => setShowRequest(false)}
                                            data={expenseDetails}
                                            status={expenseDetails[0]?.status}
                                            finalCalculatedAmount={finalCalculatedAmount}
                                            typeState={typeState}
                                        />
                                    }
                                    {reject &&
                                        <MgmRejectReason
                                            close={() => setReject(false)}
                                            expenseId={expenseDetails[0]?.id}
                                        />
                                    }
                                    {raiseQuery &&
                                        <MgmRaiseQueryScreen
                                            close={() => setRaiseQuery(false)}
                                            expenseID={expenseID}
                                        />
                                    }
                                    {partiallyApprove &&
                                        <MgmRequestScreen
                                            close={() => setpartiallyApprove(false)}
                                            data={expenseDetails}
                                            type={'partial'}
                                            finalCalculatedAmount={finalCalculatedAmount}
                                            typeState={typeState}
                                        />
                                    }
                                </>
                            ) : (
                                <>
                                    {state?.screen === "teamRequest" && loginStatus?.role?.role.split(',').includes('HOD') && (
                                        <>
                                            {showRequest &&
                                                <TeamRequestScreen
                                                    close={() => setShowRequest(false)}
                                                    data={expenseDetails}
                                                    status={expenseDetails[0]?.status}
                                                    finalCalculatedAmount={finalCalculatedAmount}
                                                    typeState={typeState}
                                                />
                                            }
                                            {reject &&
                                                <RejectReason
                                                    close={() => setReject(false)}
                                                    expenseId={expenseDetails[0]?.id}
                                                />
                                            }
                                            {raiseQuery &&
                                                <RaiseQueryScreen
                                                    close={() => setRaiseQuery(false)}
                                                    expenseID={expenseID}
                                                />
                                            }
                                            {partiallyApprove &&
                                                <TeamRequestScreen
                                                    close={() => setpartiallyApprove(false)}
                                                    data={expenseDetails}
                                                    type={'partial'}
                                                    finalCalculatedAmount={finalCalculatedAmount}
                                                    typeState={typeState}
                                                />
                                            }
                                        </>
                                    )}
                                    {state?.screen === "allRequest" && loginStatus?.role?.role.split(',').includes('HR') && (
                                        <>
                                            {showRequest &&
                                                <AllRequestScreen
                                                    close={() => setShowRequest(false)}
                                                    data={expenseDetails}
                                                    status={expenseDetails[0]?.status}
                                                    finalCalculatedAmount={finalCalculatedAmount}
                                                    typeState={typeState}
                                                />
                                            }
                                            {reject &&
                                                <AllRejectReason
                                                    close={() => setReject(false)}
                                                    expenseId={expenseDetails[0]?.id}
                                                />
                                            }
                                            {raiseQuery &&
                                                <AllRaiseQueryScreen
                                                    close={() => setRaiseQuery(false)}
                                                    expenseID={expenseID}
                                                />
                                            }
                                            {partiallyApprove &&
                                                <AllRequestScreen
                                                    close={() => setpartiallyApprove(false)}
                                                    data={expenseDetails}
                                                    type={'partial'}
                                                    finalCalculatedAmount={finalCalculatedAmount}
                                                    typeState={typeState}
                                                />
                                            }
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    }


                    {
                        invoiceView2 &&
                        <ViewInvoices
                            close={() => setinvoiceView(false)}
                            data={invoiceList}
                        />
                    }


                </div>
            }
        </div>
    )
}
