import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import description from '../../assets/images/description.svg'
import closeArrow from '../../assets/images/closeArrow.svg'
import openArrow from '../../assets/images/openArrow.svg'
import map from '../../assets/images/travel/map.svg'
import parking from '../../assets/images/travel/parking.svg'
import toll from '../../assets/images/travel/toll.svg';
import distance from '../../assets/images/travel/distance.svg'
import amount from '../../assets/images/travel/amount.svg'
import city from '../../assets/images/city.svg'
import '../expenseDetail/expenseDetailHome.css'
import { BlueCommonButton, RedCommonButton, WhiteCommonButton, CancelCommonButton } from '../../components/button.tsx'

import LoadingSpinner from "../../components/loader.tsx";
import { useSelector, useDispatch } from 'react-redux';
import { setData, selectData } from '../../Redux/features/login/loginSlicer';
import Tooltip from '@mui/material/Tooltip';
import i from '../../assets/images/i.svg'
import { formatCurrency } from "../../service/Logics.js";
import { ViewInvoices } from "../../components/viewInvoices.tsx";

export const Expensepreviewpopup = ({ setpreviewPopupShow, isLoading, expenseFilledData, addExpense }) => {

    const [editable, setEditable] = useState(false)
    const [activeCard, setActiveCard] = useState('')
    const [mealDetails, setMealDetails] = useState<any>([{ id: Number, date: '', breakfast: '', lunch: '', dinner: '', file: '' }])
    const [travelDetails, setTravelDetails] = useState<any>([{ date: '', startLocation: '', endLocation: '', travelType: '', travelMode: '', parking: '', tollTax: '', actualDistance: '', calculatedDistance: '', amount: '', file: '' }])
    const [hotelDetails, setHotelDetails] = useState<any>(expenseFilledData?.tempHotel)
    const [daDetails, setDADetails] = useState<any>([{ date: '', city: '', amount: '' }])
    const [otherData, setOtherData] = useState<any>([{ title: '', amount: '', remark: '' }])
    const renderTeamMembers = (members) => {
        const maxDisplayMembers = 1;
        if (members.length <= maxDisplayMembers) {
            return members.join(', ');
        } else {
            const displayedMembers = [members.slice(0, maxDisplayMembers)];
            const moreMembersCount = members.length - maxDisplayMembers;
            return (
                <>
                    {displayedMembers.join(', ')}
                    <div>+{moreMembersCount} more</div>
                </>
            );
        }
    };


    const handleTab = (tabName: string) => {
        setActiveCard(tabName === activeCard ? "" : tabName)
    }


    const updateHotelRow = (id: number, value: any, field: string, invoiceId: any) => {
        const updatedRows = hotelDetails.map((row, rowIndex) => row.id === id ? { ...row, [field]: value, invoiceId: invoiceId } : row);
        setHotelDetails(updatedRows)
        console.log(id, value, field, hotelDetails);
    }



    const [invoiceView, setinvoiceView] = useState(false)
    const [invoiceList, setinvoiceList] = useState([])
    const handleImageDownload = (base64, fileName) => {
        setinvoiceView(true)
        setinvoiceList(base64?.split(","))
    };



    const storedData = useSelector(setData);
    const userDetails = storedData?.payload?.login?.items[0]
    const loginStatus = useSelector(selectData);
    const filterKeys = (obj, keysToExclude) =>
        Object.fromEntries(
            Object.entries(obj).filter(([key]) => !keysToExclude.includes(key))
        );
    // Keys to exclude
    const excludedKeys = ['id', 'expense_id', 'updated_at', 'created_at', "_id"];
    const excludedTravelKeys = ['id', 'expense_id', 'updated_at', 'created_at', "invoices", "amount_remark"];


    const removeRemarks = (data) => {
        const mealsWithoutRemarks = data?.map((meal) => {
            const filteredMeal = Object.fromEntries(
                Object.entries(meal).filter(([key, _]) => !key.endsWith('_remark'))
            );
            return filteredMeal;
        });
        return mealsWithoutRemarks
    }

    useEffect(() => {
        // setExpensedetails(result?.data?.result)
        setMealDetails(removeRemarks(expenseFilledData?.tempMeal))
        // console.log("expenseFilledData?.tempHotel
        setTravelDetails(expenseFilledData?.tempTravel)
        setDADetails(removeRemarks(expenseFilledData?.tempDA))
        setHotelDetails(removeRemarks(expenseFilledData?.tempHotel))
        setOtherData(expenseFilledData?.tempOther)

        // getFinalAmount(result?.data?.result)
    }, [])





    return (
        <div className="popup-container">
            <div className='mt-10px container-main ' >
                <LoadingSpinner loading={isLoading} />
                <div>
                    <div >
                        <div className=' m-10px textAlign-Start row '>
                            <span className="bold1Rem commonBlackcolor">
                                Expense Details
                            </span>
                        </div>
                        <div className='moduleBorderWithoutPadding'>
                            <div className='d-flex alignItem-start row'>
                                <div className='m-10px'>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Start Date"
                                        type='date'
                                        value={expenseFilledData?.expenseDetails?.startDate}
                                        disabled={editable ? false : true}
                                        className='datepicker'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" className='ml-10px'>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </div>
                                <div className='m-10px'>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="End Date"
                                        type='date'
                                        disabled={editable ? false : true}
                                        value={expenseFilledData?.expenseDetails?.endDate}
                                        className='datepicker'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" className='ml-10px'>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </div>
                            </div>
                            <div className='d-flex column m-10px'>
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Expense Description"
                                    multiline
                                    maxRows={4}
                                    disabled={editable ? false : true}
                                    placeholder="Max 250 characters"
                                    value={expenseFilledData?.expenseDetails?.descriptionValue}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={description} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </div>
                        </div>

                        <div>
                            {mealDetails.length == null || mealDetails.length == 0  //meal card
                                ? null :
                                <div className="moduleBorderWithoutPadding ">
                                    <div onClick={() => handleTab("meal")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                        <span className={activeCard == "meal" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Meal Expense</span>
                                        <img src={activeCard == "meal" ? openArrow : closeArrow} />
                                    </div>
                                    <hr />
                                    <div>
                                        {
                                            activeCard == "meal" &&
                                            <>
                                                {mealDetails?.map((entry, index) => {
                                                    const filteredEntry = filterKeys(entry, excludedKeys);
                                                    return (
                                                        <div key={index} className="ml-10px">
                                                            <div className="d-flex row aa flex-wrap">
                                                                {Object?.entries(filteredEntry)?.map(([key, value]) => {
                                                                    const isAmountField = (typeof (value) == "number")
                                                                    // console.log(key, "<<<<<isAmountField");
                                                                    return (
                                                                        <div key={key}>
                                                                            {value !== "" && value !== null
                                                                                && key != "undefined"
                                                                                && key.toLowerCase() != "uid"
                                                                                && (!key.includes("_Isinvoice"))
                                                                                && (!key.includes("_id"))
                                                                                && (!key.includes("_name"))
                                                                                && (!key.includes("_type"))
                                                                                && (key != "isInvoiceFil")
                                                                                && (!key.includes("japTag"))
                                                                                && key !== "invoice_file" && !key.includes("_invoice_file") ? (
                                                                                <div className="bb">
                                                                                    <div className='m-10px'>
                                                                                        {
                                                                                            !(key.includes("_invoice") || key.includes("_invoice_file") ) &&
                                                                                            <>
                                                                                                {
                                                                                                    (isAmountField) && Number(value) == 0 ? "" :
                                                                                                        <TextField
                                                                                                            id="input-with-icon-textfield"
                                                                                                            label={key === "ex_date" ? "Date" : key.charAt(0).toUpperCase() + key.slice(1)?.replace(/_/g, " ")}
                                                                                                            type={key === "ex_date" ? 'date' : "string"}
                                                                                                            defaultValue={key.includes("_tag") ? value.split(',')[0] : value}
                                                                                                            disabled={editable ? false : true}
                                                                                                            className='datepicker'
                                                                                                            InputProps={{
                                                                                                                startAdornment: (
                                                                                                                    <InputAdornment position="start" className='ml-10px'>
                                                                                                                    </InputAdornment>
                                                                                                                ),
                                                                                                            }}
                                                                                                            variant="standard"
                                                                                                        />
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            typeof (value) != "Boolean" && key.includes("_invoice") ?
                                                                                                (
                                                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                                                        <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(value, 'file')}>
                                                                                                            View {value ? key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1) : null}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            {key.includes("_tag") && value !== "" ? (
                                                                                <div className='m-5px light0_875Rem d-flex'>
                                                                                    <Tooltip
                                                                                        title={`Tagged members: ${value}`} arrow>
                                                                                        <div className="d-flex">
                                                                                            <span className='m-5px light0_875Rem '>
                                                                                                Tag: {renderTeamMembers(value?.split(',')?.map(member => member.trim()))}
                                                                                            </span>
                                                                                            <img src={i} className='iImg m-5px' />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            ) : null}

                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {travelDetails.length == null || travelDetails.length == 0  //travel card 
                                ? null :
                                <div className="moduleBorderWithoutPadding ">
                                    <div onClick={() => handleTab("travel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                        <span className={activeCard == "travel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Travel Expense</span>
                                        <img src={activeCard == "travel" ? openArrow : closeArrow} />
                                    </div>
                                    <hr />
                                    <div className='d-flex column pl-20px pr-20px' >
                                        {
                                            activeCard == "travel" &&
                                            <>
                                                {
                                                    travelDetails?.map((entry, index) => {
                                                        const filteredEntry = filterKeys(entry, excludedTravelKeys);
                                                        // console.log(entry, "<<<<entry");
                                                        return (
                                                            <div key={index}>
                                                                <div className="d-flex row aa flex-wrap">
                                                                    <div className='d-flex row flex-wrap'>
                                                                        {
                                                                            entry.vehicle != "" &&
                                                                            <div className='m-10px new-text-class'>
                                                                                <TextField
                                                                                    id={`input-actualDistance-textfield`}
                                                                                    label={"Vehicle Name"}
                                                                                    type={"text"}
                                                                                    disabled={true}
                                                                                    placeholder={`Enter vehicle`}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <div>
                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                    <img src={distance} />
                                                                                                </InputAdornment>
                                                                                            </div>
                                                                                        ),
                                                                                    }}
                                                                                    variant="standard"
                                                                                    value={entry.vehicle}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Date"
                                                                                placeholder='Enter Date here'
                                                                                type='date'
                                                                                disabled={true}
                                                                                className='datepicker'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <div>
                                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                                {/* {field.title === 'amount' ? <img src={bill} /> : field.title === "Calculated distance" ? <img src={distance} /> : field.title === "date" ? "" : field.title === "amount" ? <img src={bill} /> : <img src={map} />} */}
                                                                                            </InputAdornment>
                                                                                        </div>
                                                                                    ),
                                                                                }}

                                                                                variant="standard"
                                                                                value={entry.ex_date}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            entry?.is_selfBook &&
                                                                            <div className='m-10px'>
                                                                                <TextField
                                                                                    id="input-with-icon-textfield"
                                                                                    label="Self Booked"
                                                                                    placeholder='Enter Date here'
                                                                                    type='text'
                                                                                    disabled={true}
                                                                                    className='datepicker'
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <div>
                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                    {/* {field.title === 'amount' ? <img src={bill} /> : field.title === "Calculated distance" ? <img src={distance} /> : field.title === "date" ? "" : field.title === "amount" ? <img src={bill} /> : <img src={map} />} */}
                                                                                                </InputAdornment>
                                                                                            </div>
                                                                                        ),
                                                                                    }}

                                                                                    variant="standard"
                                                                                    value={entry?.is_selfBook}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <div className='m-10px d-flex'>
                                                                            <TextField
                                                                                id={`input-startLoaction-textfield`}
                                                                                label={"Start Location"}
                                                                                type={"string"}
                                                                                disabled={true}
                                                                                multiline
                                                                                maxRows={4}
                                                                                placeholder={`Choose start location`}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <div
                                                                                        // onClick={() => openMapFunction('start_location', dataValue.uid, item?.policies[0])}
                                                                                        >
                                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                                <img src={distance} />
                                                                                            </InputAdornment>
                                                                                        </div>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                                value={entry?.start_location}
                                                                            />
                                                                            {entry?.start_location !== "" &&
                                                                                <Tooltip
                                                                                    key={entry.start_location}
                                                                                    title={entry.start_location} arrow >
                                                                                    <div className="d-flex">
                                                                                        <span className='m-5px light0_875Rem '>
                                                                                            {/* {'Full address'} */}
                                                                                        </span>
                                                                                        <img src={i} className='iImg m-5px' />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        </div>
                                                                        <div className='m-10px d-flex'>
                                                                            <TextField
                                                                                id={`input-endLoaction-textfield`}
                                                                                label={"End Location"}
                                                                                type={"string"}
                                                                                disabled={true}
                                                                                multiline
                                                                                maxRows={4}
                                                                                placeholder={`Choose end location`}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <div
                                                                                            onClick={() => openMapFunction('end_location', entry.uid, item?.policies[0])}
                                                                                        >
                                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                                <img src={distance} />
                                                                                            </InputAdornment>
                                                                                        </div>
                                                                                    ),
                                                                                }}
                                                                                variant="standard"
                                                                                value={entry.end_location}
                                                                            />

                                                                            {entry?.end_location !== "" &&
                                                                                <Tooltip
                                                                                    title={entry.end_location} arrow >
                                                                                    <div className="d-flex">
                                                                                        <span className='m-5px light0_875Rem '>
                                                                                            {/* {'Full address'} */}
                                                                                        </span>
                                                                                        <img src={i} className='iImg m-5px' />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            entry.actual_distance &&
                                                                            <div className='m-10px new-text-class'>
                                                                                <TextField
                                                                                    id={`input-actualDistance-textfield`}
                                                                                    label={"Actual Distance"}
                                                                                    type={"number"}
                                                                                    disabled={true}
                                                                                    placeholder={`Enter actual distance`}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <div>
                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                    <img src={distance} />
                                                                                                </InputAdornment>
                                                                                            </div>
                                                                                        ),
                                                                                    }}
                                                                                    variant="standard"
                                                                                    value={entry?.actual_distance || entry?.calculated_distance}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            entry.has_base_location &&
                                                                            <div className='m-10px new-text-class'>
                                                                                <TextField
                                                                                    id={`input-actualDistance-textfield`}
                                                                                    label={"Has base location"}
                                                                                    type={"text"}
                                                                                    disabled={true}
                                                                                    placeholder={``}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <div>
                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                    <img src={distance} />
                                                                                                </InputAdornment>
                                                                                            </div>
                                                                                        ),
                                                                                    }}
                                                                                    variant="standard"
                                                                                    value={entry?.has_base_location}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            entry.amount != "" &&
                                                                            <div className='m-10px new-text-class'>
                                                                                <TextField
                                                                                    id={`input-actualDistance-textfield`}
                                                                                    label={"Amount"}
                                                                                    type={"text"}
                                                                                    disabled={true}
                                                                                    placeholder={`Enter amount`}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <div>
                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                    <img src={amount} />
                                                                                                </InputAdornment>
                                                                                            </div>
                                                                                        ),
                                                                                    }}
                                                                                    variant="standard"
                                                                                    value={formatCurrency(Number(entry.amount || 0), "₹")}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            entry?.invoice_file &&
                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(entry?.invoice_file, 'file')}>
                                                                                    View Invoice
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            entry?.bording_pass &&
                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle ' style={{ cursor: "pointer" }} onClick={() => handleImageDownload(entry?.bording_pass, 'file')}>
                                                                                    View Boarding Pass
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            entry?.parking != undefined && entry?.parking[0]?.value > 0 &&
                                                                            <>
                                                                                {
                                                                                    entry.parking?.map((item, index) => {
                                                                                        return (
                                                                                            <div key={index} className='m-10px new-text-class'>
                                                                                                <TextField
                                                                                                    id={`input-actualDistance-textfield`}
                                                                                                    label={"Parking Amount"}
                                                                                                    type={"number"}
                                                                                                    disabled={true}
                                                                                                    placeholder={`Enter Parking`}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (
                                                                                                            <div>
                                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                                    <img src={parking} />
                                                                                                                </InputAdornment>
                                                                                                            </div>
                                                                                                        ),
                                                                                                    }}
                                                                                                    variant="standard"
                                                                                                    value={item?.value}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }
                                                                        {
                                                                            entry?.toll != undefined && entry.toll[0]?.value > 0 &&
                                                                            <>
                                                                                {
                                                                                    entry.toll?.map((item, index) => {
                                                                                        return (
                                                                                            <div key={index} className='m-10px new-text-class'>
                                                                                                <TextField
                                                                                                    id={`input-actualDistance-textfield`}
                                                                                                    label={"Toll Amount"}
                                                                                                    type={"number"}
                                                                                                    disabled={true}
                                                                                                    placeholder={`Enter Toll`}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (
                                                                                                            <div>
                                                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                                                    <img src={parking} />
                                                                                                                </InputAdornment>
                                                                                                            </div>
                                                                                                        ),
                                                                                                    }}
                                                                                                    variant="standard"
                                                                                                    value={item?.value}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {hotelDetails.length === null || hotelDetails.length === 0 ? null :    //hotel card 
                                <div className="moduleBorderWithoutPadding">
                                    <div onClick={() => handleTab("hotel")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                        <span className={activeCard == "hotel" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Hotel Expense</span>
                                        <img src={activeCard == "hotel" ? openArrow : closeArrow} />
                                    </div>
                                    <hr />
                                    <div className='d-flex column pl-20px pr-20px' >
                                        {
                                            activeCard == "hotel" &&
                                            <>
                                                {
                                                    hotelDetails?.map((item, id) => {
                                                        console.log(item, "<<<<<'amount'");

                                                        return (
                                                            <div className='d-flex row alignItem-center flex-wrap'>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="City"
                                                                        type='string'
                                                                        disabled={editable ? false : true}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={city} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        value={item?.city}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Check In"
                                                                        type='date'
                                                                        value={item?.check_in_date}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Check Out"
                                                                        type='date'
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        value={item?.check_out_date}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Number of days"
                                                                        value={item?.days_count}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="GST %"
                                                                        value={item?.gst_percentage + "%"}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Amount (inclusive GST)"
                                                                        value={formatCurrency(Number(item?.amount_withGst), '₹')}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                {
                                                                    item?.invoices ? (
                                                                        item.invoices?.map((invoice) => (
                                                                            <>
                                                                                <div className='m-10px' key={invoice.id}>
                                                                                    <TextField
                                                                                        id="input-with-icon-textfield"
                                                                                        label="Amount Amount (Excluding GST)"
                                                                                        type='number'
                                                                                        defaultValue={invoice?.amount ? invoice?.amount : ""}
                                                                                        disabled={editable ? false : true}
                                                                                        placeholder='Select location'
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <img src={amount} />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            console.log("<<<hotelDetails", item);
                                                                                            updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice.id)

                                                                                        }}
                                                                                        // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    invoice?.invoice_file !== null ?
                                                                                        <div className='m-10px d-flex alignItem-center'>
                                                                                            <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                                View Invoice File
                                                                                            </span>
                                                                                        </div>
                                                                                        : ""
                                                                                }
                                                                            </>
                                                                        ))
                                                                    ) : (

                                                                        <>         <div className='m-10px'>
                                                                            <TextField
                                                                                id="input-with-icon-textfield"
                                                                                label="Amount"
                                                                                type='number'
                                                                                defaultValue={item?.amount}
                                                                                disabled={editable ? false : true}
                                                                                placeholder='Select location'
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            <img src={amount} />
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    updateHotelRow(item?.id, Number(e.target.value), 'amount', invoice.id)
                                                                                }}
                                                                                // onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                                                variant="standard"
                                                                            />
                                                                        </div>


                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(invoice?.invoice_file, 'file')}>
                                                                                    View Invoice File
                                                                                </span>
                                                                            </div>

                                                                        </>
                                                                    )
                                                                }

                                                                {
                                                                    item?.hotel_tag !== "" ?
                                                                        <>
                                                                            <div className='m-5px light0_875Rem d-flex'>
                                                                                <Tooltip
                                                                                    title={`Tagged members: ${item?.hotel_tag}`} arrow>
                                                                                    <div className="d-flex">
                                                                                        <span className='m-5px light0_875Rem '>
                                                                                            Tag: {renderTeamMembers(item?.hotel_tag?.split(',')?.map(member => member.trim()))}
                                                                                        </span>
                                                                                        <img src={i} className='iImg m-5px' />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>

                                                                        </> : ""
                                                                }


                                                            </div>

                                                        )
                                                    }
                                                    )}

                                            </>
                                        }
                                    </div>
                                </div>
                            }

                            {daDetails.length === null || daDetails.length === 0 ? null :   // da card 
                                <div className="moduleBorderWithoutPadding">
                                    <div onClick={() => handleTab("da")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                        <span className={activeCard == "da" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>DA Expense</span>
                                        <img src={activeCard == "da" ? openArrow : closeArrow} />
                                    </div>
                                    <hr />
                                    <div className='d-flex column pl-20px pr-20px' >
                                        {
                                            activeCard == "da" &&
                                            <>
                                                {daDetails?.map((item, id) =>
                                                    <div className='d-flex row alignItem-center flex-wrap' key={id}>
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="Date"
                                                                type='date'
                                                                disabled={editable ? false : true}
                                                                value={item?.ex_date || item?.date}
                                                                className='datepicker'
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="City"
                                                                disabled={editable ? false : true}
                                                                value={item?.city}
                                                                type='string'
                                                                placeholder='Enter amount'
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <img src={city} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="Amount"
                                                                type='text'
                                                                disabled={editable ? false : true}
                                                                value={formatCurrency(Number(item?.amount || 0), "₹")}
                                                                placeholder='Amount'
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <img src={amount} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div className="m-10px">
                                                            {
                                                                item?.invoice_file ?
                                                                    <div className='m-10px d-flex alignItem-center'>
                                                                        <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(item?.invoice_file, 'file')}>
                                                                            View Invoice File
                                                                        </span>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {otherData.length === null || otherData.length === 0 ? null :    //hotel card 
                                <div className="moduleBorderWithoutPadding">
                                    <div onClick={() => handleTab("other")} className="d-flex space-between alignItem-center curser pl-20px pr-20px">
                                        <span className={activeCard == "other" ? "activetab tabSupport bold0_875Rem" : "deActivetab tabSupport bold0_875Rem"}>Other Expense</span>
                                        <img src={activeCard == "other" ? openArrow : closeArrow} />
                                    </div>
                                    <hr />
                                    <div className='d-flex column pl-20px pr-20px' >
                                        {
                                            activeCard === "other" &&
                                            <>
                                                {
                                                    otherData?.map((item, id) => {
                                                        console.log("other item ", item);
                                                        return (
                                                            <div className='d-flex row alignItem-center flex-wrap'>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Date"
                                                                        type='date'
                                                                        disabled={editable ? false : true}
                                                                        value={item?.date}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Title"
                                                                        type='string'
                                                                        disabled={editable ? false : true}
                                                                        placeholder='Enter title'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img src={city} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        value={item?.title}
                                                                        variant="standard"
                                                                    />
                                                                </div>
                                                                {
                                                                    item?.lunch_day_count &&
                                                                    <div className='m-10px'>
                                                                        <TextField
                                                                            id="input-with-icon-textfield"
                                                                            label="Number of days"
                                                                            type='string'
                                                                            disabled={editable ? false : true}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src={city} />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            value={item?.lunch_day_count}
                                                                            variant="standard"
                                                                        />
                                                                    </div>
                                                                }
                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Amount"
                                                                        value={formatCurrency(Number(item?.amount || 0), "₹")}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>

                                                                <div className='m-10px'>
                                                                    <TextField
                                                                        id="input-with-icon-textfield"
                                                                        label="Remark"
                                                                        value={item?.remark}
                                                                        disabled={editable ? false : true}
                                                                        className='datepicker'
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </div>

                                                                <div className="m-10px">
                                                                    {
                                                                        item?.invoice_file ?
                                                                            <div className='m-10px d-flex alignItem-center'>
                                                                                <span className='m-5px light0_875Rem txtstyle' onClick={() => handleImageDownload(item?.invoice_file, 'file')}>
                                                                                    View Invoice File
                                                                                </span>
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                    )}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        <div className='d-flex row flex-wrap'>
                            <div className='m-10px'>
                                <CancelCommonButton
                                    title={"Cancel"}
                                    buttonClick={() => {
                                        setpreviewPopupShow((preState) => ({
                                            ...preState,
                                            isOpen: false
                                        }))
                                    }}
                                />
                            </div>
                            <div className='m-10px'>
                                <WhiteCommonButton
                                    title={"Submit"}
                                    subTitle={""}
                                    buttonClick={() => addExpense()}
                                />
                            </div>

                        </div>
                    }
                </div>

                {
                    invoiceView &&
                    <ViewInvoices
                        close={() => setinvoiceView(false)}
                        data={invoiceList}
                    />
                }

            </div>
        </div>
    )
}
