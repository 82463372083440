import React, { useState } from 'react';
import './App.css';
import ResponsiveDrawer from './navigations/navigationContainer.tsx';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoginScreen } from './screens/login/loginScreen.tsx';
import { InvalidURL } from './screens/error/invalidURL.tsx';
import { useIdleTimer } from "react-idle-timer";

import { useDispatch, useSelector } from 'react-redux';
import { selectData, setLoginStatus } from './Redux/features/login/loginSlicer.js'

function App() {
  const loginStatus = useSelector(selectData);

  const TIMEOUT = 30 * 60 * 1000;
  const GENERAL_DEBOUNCE_TIME = 300;

  const dispatch = useDispatch();

  const handleLogout = () => {
    if (loginStatus.loginStatus) {
      dispatch(setLoginStatus(false))
      alert("Your Session Expired");

    }
  }

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleLogout,
    debounce: GENERAL_DEBOUNCE_TIME,
  });
  return (
    <div className="App">
      {
        !loginStatus.loginStatus ?
          <Router>
            <Routes>
              <Route path='/*' element={<LoginScreen />} />
              <Route path='/' element={<LoginScreen />} />
            </Routes>
          </Router>
          :
          <Router>
            <ResponsiveDrawer />
          </Router>
      }
    </div>
  );
}

export default App;
