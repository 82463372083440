export  const formatCurrency = (number, currencySymbol = '₹') => {
    // Check if the input is a valid number
    if (isNaN(number)) { return ''; }
    // Convert the number to a string and split it into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1];
    // Add commas to separate thousands
    let formattedIntegerPart = '';
    for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
      formattedIntegerPart = integerPart[i] + (j > 0 && j % 3 === 0 ? ',' : '') + formattedIntegerPart;
    }
    return currencySymbol + formattedIntegerPart + '.' + decimalPart;
  };