import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { downloadImage } from './downloadFile';

// import cross from '../assets/images/cross.svg';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface modelProps {
    close: Function;
    data: any
}
export const ViewInvoices: React.FC<modelProps> = ({ close, data }) => {
    const [open, setOpen] = React.useState(true);
    return (
        <div >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open} style={{maxWidth:"350px"}} >
                    <Box sx={style}>
                        <div className='d-flex column'>
                            <div className='pl-10px pr-10px pt-10px alignItem-center d-flex space-between row'>
                                <span className='bold1Rem commonBlackcolor m-10px'>List of Uploaded Invoices</span>
                                <span onClick={() => close()} style={{cursor:"pointer"}}>
                                    <svg width="35" height="35" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_636_10947)">
                                            <circle cx="28" cy="24" r="16" fill="#027DC2" />
                                        </g>
                                        <path d="M20.8516 16L36.0002 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                        <path d="M35.1484 16L19.9998 31.1487" stroke="white" stroke-width="1.75" stroke-linecap="round" />
                                        <defs>
                                            <filter id="filter0_d_636_10947" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="6" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.301961 0 0 0 0 0.32549 0 0 0 0 0.878431 0 0 0 0.18 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_636_10947" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_636_10947" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </span>
                                {/* <img src={require('../assets/images/cross.svg')} className='popUp-cross' onClick={() => close()} /> */}
                            </div>
                            <div className='tagContainer'>
                                {
                                    data?.map((invoice: string, id) =>
                                        <div key={id} className='m-10px tagCard d-flex'>
                                            <a href='#' onClick={()=>{
                                                downloadImage(invoice, "file")
                                            }}>
                                            <span className='p-15px light1Rem'>
                                               {id+1}. View Invoice
                                            </span>
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}